import { AxiosError } from "axios";
import { createAsyncAction } from "typesafe-actions";


import { ProtoLinearResponse } from "../../types/responses";
import { ProtoLinearParams } from "../../types/requests";

export const actions = {
    getProtoLinear: createAsyncAction(
    "api/GET_PROTO_LINEAR_REQUEST",
    "api/GET_PROTO_LINEAR_SUCCESS",
    "api/GET_PROTO_LINEAR_FAILURE"
  )<ProtoLinearParams, ProtoLinearResponse, AxiosError>(),
};