import React, { useEffect } from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IMsalContext, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthenticationResult, SilentRequest, EventType } from "@azure/msal-browser";
import { useDeepCompareCallback } from "use-deep-compare";
import { push } from "connected-react-router";

import PrivateRoute from "./PrivateRoute";
import { StoreState } from "../state";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import { getConfig } from "../utils/getConfig";
import { loginRequest, ssoSilent } from "../authConfig";
import { setUserInfo } from "../state/api/actionCreators";

let currentPath = window.location.pathname; //THIS IS THE CURRENT PATH AT WHAT THE PAGE EITHER LOADED FIRST TIME OR GOT REFRESHED/RELOADED
if (currentPath.includes('auth')) {
  currentPath = '';
}

const AuthRouter = React.lazy(
  () => import(/* webpackChunkName: "main-auth-router" */ "./AuthRouter")
);

const AppRouter = React.lazy(
  () => import(/* webpackChunkName: "main-app-router" */ "./AppRouter")
);

function AuthGate(): JSX.Element {
  const { instance, accounts }: Partial<IMsalContext> = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { currentUser } = useSelector((state: StoreState) => state.user);
  
  const dispatch = useDispatch();
  let history = useHistory();

  if (accounts[0] && accounts[0].username && !sessionStorage.getItem('userEmail')) {
    sessionStorage.setItem('userEmail', accounts[0].username)
  }

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
            const {state} = message.payload;
            //history.push(state);
            dispatch(push(state));
        }
    });

    return () => {
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
    }
    
}, []);

  const setCurrentUser = useDeepCompareCallback(async () => {
    if (!accounts.length)
      throw Error("You need to be authenticated to fetch an access token");
    const response: AuthenticationResult = await instance.acquireTokenSilent({
      ...ssoSilent,
      account: accounts[0]
    } as SilentRequest);
    dispatch(setUserInfo(response));
  }, [accounts, instance]);

  useEffect(() => {
    if (!isAuthenticated || currentUser) return;
    setCurrentUser();
  }, [isAuthenticated, currentUser, setCurrentUser]);

  return (
    <Switch>
      <UnauthenticatedRoute
        isAuthenticated={isAuthenticated}
        path="/auth"
        component={AuthRouter}
        currentPath={currentPath}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path="/"
        component={AppRouter}
      />
      <Redirect to="/auth" />
    </Switch>
  );
}

export default AuthGate;
