import { Configuration, RedirectRequest, SsoSilentRequest, LogLevel } from "@azure/msal-browser";
import { getConfig } from "./utils/getConfig";

const searchParams = new URLSearchParams(window.location.search);
const searchHost = window.location.hostname;
const userHint = searchParams.get("user_hint")
let userName;
let scopesConfig;
let redirectConfig;
let logoutRedirectConfig;

if (searchHost.includes('corp.ad.ctc')) { // internal
    if (searchHost.includes('agw')) { // agw
        redirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGIN_REDIRECT_EXT");
        logoutRedirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGOUT_REDIRECT_EXT");
        scopesConfig = getConfig("MSALSCOPE");
    } else {
        redirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGIN_REDIRECT");
        logoutRedirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGOUT_REDIRECT");
        scopesConfig = getConfig("MSALSCOPE");
    }
} else if (searchHost.includes('cantire.com')) { // external
    redirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGIN_REDIRECT_EXT");
    logoutRedirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGOUT_REDIRECT_EXT");
    scopesConfig = getConfig("MSALSCOPE");
} else {
    redirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGIN_REDIRECT"); // for localhost
    logoutRedirectConfig = getConfig("REACT_APP_OAUTH_POST_LOGOUT_REDIRECT");
    scopesConfig = getConfig("MSALSCOPE");
}

if (!userHint) {
    userName = undefined;
} else {
    userName = userHint;
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: getConfig("REACT_APP_OAUTH_APP_ID"),
        navigateToLoginRequestUrl: false,
        authority: getConfig("REACT_APP_OAUTH_TENANT_ID"),
        postLogoutRedirectUri: logoutRedirectConfig,
        redirectUri: redirectConfig,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    // case LogLevel.Info:		
                    //     console.info(message);		
                    //     return;		
                    // case LogLevel.Verbose:		
                    //     console.debug(message);		
                    //     return;		
                    // case LogLevel.Warning:		
                    //     console.warn(message);		
                    //     return;		
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [scopesConfig],
    redirectUri: redirectConfig,
};

export const ssoSilent: SsoSilentRequest = {
    scopes: [scopesConfig],
    loginHint: userName
};

(window as any).whatVersion = "0.27.219"; // Version
