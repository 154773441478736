import {
  AnyAction,
  Store,
  StoreEnhancer,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";
import {
  RouterState,
  connectRouter,
  routerMiddleware,
} from "connected-react-router";
import { History, createBrowserHistory } from "history";
import thunk, { ThunkAction } from "redux-thunk";
import { ActionType } from "typesafe-actions";

import * as Api from "../services/apiClient";
import * as Fetch from "../services/fetchClient";
import { ApiState, api } from "./api/reducer";
import { actions as apiActions } from "./api/actions";
import { actions as apiFixturesActions } from "../state/fixtures/fixtures.actions";
import { user, UserState } from "./user/reducer";
import { assortment, AssortmentState } from "./assortment/reducer";
import {
  optimizationConfiguration,
  OptimizationConfigurationState,
} from "./optimizationConfiguration/reducer";
import { job, jobs, planogramFilter, metricJob, solverOutput } from "./job/reducer";
import type {
  JobState,
  JobsState,
  PlanogramState,
  PlanogremFilterState,
  MetricJobState,
  SolverOutputState,
  ProductsState,
  ProductsListState,
  PackagingState,
} from "./job/types";
import type {
  BlockingState,
  BlockingListState,
} from "./blocking/types";
import { comparison, ComparisonState } from "./comparison/reducer";
import { getConfig } from "../utils/getConfig";
import { reviewAndEdit, ReviewAndEditState } from "./viewAndEdit/reducer";
import { notification, NotificationState } from "./notifications/reducer";
import { products, productsList } from "./products/reducer";
import { packaging } from "./packaging/reducer";
import { blocking, blockingList } from "./blocking/reducer";
import { workFlow } from "./workFlow/reducer";
import { WorkFlowState } from "./workFlow/types";
import { globalState } from './tetrisGlobal/reducer';
import { TetrisGlobalState } from './tetrisGlobal/types'
import { ProtoLinearState } from "./protoLinear/types";
import { protoLinearList } from "./protoLinear/reducer";
import { VendorPegboardState } from "./vendorPegboard/types";
import { vendorPegboard } from "./vendorPegboard/reducer";
import { fixtures, fixturesNotchesHoles } from "./fixtures/fixtures.reducer";
import type { FixturesState, FixturesNotchesHolesState } from "./fixtures/fixtures.types";

const agregatedActions = {
  ...apiActions,
  ...apiFixturesActions,
};

export type Actions = ActionType<typeof agregatedActions>;

export interface ThunkExtraArgument {
  apiClientJava: Api.ApiClient;
  fetchClientJava: Fetch.FetchClient;
}

type ThunkResult<R> = ThunkAction<R, StoreState, ThunkExtraArgument, Actions>;

export type ThunkActionCreator<P = void, R = void> = (
  params: P
) => ThunkResult<R>;

export interface StoreState {
  api: ApiState;
  assortment: AssortmentState;
  comparison: ComparisonState;
  user: UserState;
  router: RouterState;
  notification: NotificationState;
  optimizationConfiguration: OptimizationConfigurationState;
  reviewAndEdit: ReviewAndEditState;
  jobs: JobsState;
  job: JobState;
  planogramFilter: PlanogremFilterState;
  metricJob: MetricJobState,
  solverOutput: SolverOutputState,
  workFlow: WorkFlowState,
  globalState: TetrisGlobalState,
  products: ProductsState,
  productsList: ProductsListState,
  packaging: PackagingState,
  blocking: BlockingState,
  blockingList: BlockingListState,
  protoLinearList:ProtoLinearState,
  vendorPegboard: VendorPegboardState,
  fixtures: FixturesState,
  fixturesNotchesHoles: FixturesNotchesHolesState,
}

interface InitStore {
  store: Store<StoreState>;
  history: History;
}

const BACKEND_JAVA_URL = getConfig('REACT_APP_BACKEND_JAVA_URL');
const BACKEND_JAVA_URL_EXT = getConfig('REACT_APP_BACKEND_JAVA_URL_EXT');

// maybe we can move this function to some helpers file
const getJavaInstance = () => {
  const searchHost = window.location.hostname;
  if (searchHost.includes('corp.ad.ctc')) {
    if (searchHost.includes('agw')) { // agw
      return BACKEND_JAVA_URL_EXT;
    } else {
      return BACKEND_JAVA_URL;
    }
  } else if (searchHost.includes('cantire.com')) { // external
    return BACKEND_JAVA_URL_EXT;
  } else {
    return BACKEND_JAVA_URL;
  }
}
//maybe we can move this function to some helpers file

const axiosInstanceJava = Api.createAxiosInstance(getJavaInstance());
export const apiClientJava = new Api.ApiClient(axiosInstanceJava);
export const fetchClientJava = new Fetch.FetchClient(getJavaInstance());

export function initStore(): InitStore {
  const enhancers: Array<StoreEnhancer> = [];

  const publicURL = process.env.PUBLIC_URL;
  const history = createBrowserHistory({
    basename: publicURL,
  });

  const middlewares = [
    routerMiddleware(history),
    thunk.withExtraArgument({ apiClientJava, fetchClientJava }),
  ];

  if (
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore<StoreState, AnyAction, any, any>(
    combineReducers({
      api,
      assortment,
      comparison,
      user,
      notification,
      optimizationConfiguration,
      reviewAndEdit,
      router: connectRouter(history),
      jobs,
      job,
      planogramFilter,
      metricJob,
      solverOutput,
      workFlow,
      globalState,
      products,
      productsList,
      packaging,
      blocking,
      blockingList,
      protoLinearList,
      vendorPegboard,
      fixtures,
      fixturesNotchesHoles
    }),
    compose(applyMiddleware(...middlewares), ...enhancers)
  );

  return {
    store,
    history,
  };
}
