import { downloadFile, openFile } from '../helpers/utils';
import { DownloadAssortmentExcelFileParam, DownloadOutputFileParam, DownloadDOSFileParam, GetFileParams } from '../types/requests';
import msalService from './msalService';
import { getConfig } from '../utils/getConfig';
import { APIMSUBSCRIPTION_DEFAULT_VALUE } from '../utils/constants';
import { DownloadOutputFilesResponse, PDFResponse } from '../types/responses';

export class FetchClient {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getAccessToken() {
    try {
      const accessToken = await msalService.checkAccessToken();
      if (accessToken) {
        return accessToken;
      }
    } catch (e) {
      console.log('msalHandleAccesToken error', e);
    }
  }

  async requestHandlerPost(url: string, data = {}) {
    try {
      const requestURL = `${this.baseUrl}${url}`;
      const token = `Bearer ${await this.getAccessToken()}`;
      const myHeaders = new Headers();
      const APIMSUBSCRIPTION = getConfig('APIMSUBSCRIPTION') || APIMSUBSCRIPTION_DEFAULT_VALUE;

      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Ocp-Apim-Subscription-Key", APIMSUBSCRIPTION);

      const raw = JSON.stringify(data);
      const requestOption = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      } as const;

      const response = await fetch(requestURL, requestOption);
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async requestHandlerGet(url: string) {
    try {
      const requestURL = `${this.baseUrl}${url}`;
      const token = `Bearer ${await this.getAccessToken()}`;
      const myHeaders = new Headers();
      const APIMSUBSCRIPTION = getConfig('APIMSUBSCRIPTION') || APIMSUBSCRIPTION_DEFAULT_VALUE;

      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Ocp-Apim-Subscription-Key", APIMSUBSCRIPTION);

      const requestOption = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        responseType: "blob"
      } as const;

      const response = await fetch(requestURL, requestOption);
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async downloadAssortmentExcelFile(
    params: DownloadAssortmentExcelFileParam
  ): Promise<void> {
    const { fileName, storeId, aisleName, reqBody } = params;
    const excelFileName = `${fileName}.xlsm`;
    const url = "pog-setup-guide/export-assortment";
    const data = {
      responseType: "blob",
      reqBody,
      aisleName,
      storeId
    };

    try {
      const excelResponse = await this.requestHandlerPost(url, data);
      if (excelResponse?.ok) {
        const excelBlob = await excelResponse.blob();
        downloadFile(excelBlob, excelFileName);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async downloadOutputFiles(
    params: DownloadOutputFileParam
  ): Promise<DownloadOutputFilesResponse> {
    const { csvUrl, pdfUrl, fileName, alerts, lang, includeImages } = params;
    const csvFilename = `${fileName}.csv`;
    const pdfFilename = `${fileName}.pdf`;

    try {
      if (csvUrl !== null) {
        const csvResponse = await this.requestHandlerGet(csvUrl);

        if (csvResponse?.ok) {
          const csvBlob = await csvResponse.blob();
          downloadFile(csvBlob, csvFilename);
        }
      }

      if (includeImages) {
        const pdfResponse = await this.requestHandlerGet(pdfUrl);
        const pdfResponseObj: PDFResponse = await pdfResponse?.json();
        const { status, data } = pdfResponseObj;

        if (status === "DONE" && data !== null) {
          const pdfBlob = new Blob([new Int8Array(data)], { type: "application/pdf" });;
          await downloadFile(pdfBlob, pdfFilename);
          return { status: "DONE" };
        }

        return { status };
      } else {
        const pdfResponse = await this.requestHandlerPost(pdfUrl, {
          responseType: "blob",
          alerts,
          lang,
          includeImages
        });

        if (pdfResponse?.ok) {
          const pdfBlob = await pdfResponse.blob();
          await downloadFile(pdfBlob, pdfFilename);
        }

        return { status: "DONE" };
      }
    } catch (error) {
      return { status: "FAILED" };
    }
  }

  async downloadDOSFiles(
    params: DownloadDOSFileParam
  ): Promise<void> {
    const {
      name,
      lang,
      store_num,
      aisle_code,
      product_nums,
      starting_assortment,
      demand,
      labels,
      use_iss,
      use_prom,
      aisle_geometry } = params;
    const filename = `${name}.pdf`;
    const url = 'pog-setup-guide/data-only-solution'

    try {
      const response = await this.requestHandlerPost(url, {
        store_num,
        lang,
        aisle_code,
        product_nums,
        starting_assortment,
        demand,
        labels,
        use_iss,
        use_prom,
        aisle_geometry
      });

      if (response?.ok) {
        const blob = await response.blob();
        downloadFile(blob, filename);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getFile(
    params: GetFileParams
  ): Promise<any> {
    const { pogNumber, name } = params;

    const pdfUrl = `stores/data/file`;
    const pdfResponse = await this.requestHandlerPost(pdfUrl, { PogNumber: pogNumber });

    if (pdfResponse?.ok) {
      const pdfBlob = await pdfResponse.blob();
      await openFile(pdfBlob, name);
    }
  }
};
