export const DraggableTargets = {
  Shelf: "SHELF",
  Basket: "BASKET",
  Crossbar: "CROSSBAR",
  Pegboard: "PEGBOARD",
  Flatbar: "FLATBAR"
};

export type FixtureType = "SHELF" | "BASKET" | "CROSSBAR" | "PEGBOARD" | "FLATBAR";

export enum FIXTURE_TYPES {
  SHELF = "SHELF",
  BASKET = "BASKET",
  PEGBOARD = "PEGBOARD",
  CROSSBAR = "CROSSBAR",
  SPECIALTY = "SPECIALTY",
  FLATBAR = "FLATBAR"
};

export enum FIXTURE_TYPES_NUMBERS {
  SHELF = "01",
  BASKET = "02",
  PEGBOARD = "03",
  CROSSBAR = "04",
  SPECIALTY = "05",
  FLATBAR = "06"
};

export enum DISTRICT_TYPES {
  CLIPBOARD = "CLIPBOARD",
  PLANOGRAM = "PLANOGRAM",
  WORKBENCH = "WORKBENCH"
};

export enum DROP_EFFECTS {
  COPY = "copy",
  MOVE = "move"
};

export const acceptedFixtureType = {
  [DraggableTargets.Basket]: [
    DraggableTargets.Basket,
    DraggableTargets.Shelf,
    DraggableTargets.Crossbar,
    DraggableTargets.Pegboard,
  ],
  [DraggableTargets.Shelf]: [
    DraggableTargets.Basket,
    DraggableTargets.Shelf,
  ]
}

export const DraggableItems = {
  YCapped: "Y_CAPPED",
  YNested: "Y_NESTED",
  ZNested: "Z_NESTED",
  DefauldItem: "NONE"
};

export const DraggableWorkbenchItems = {
  YCapped: "WORKBENCH_Y_CAPPED",
  YNested: "WORKBENCH_Y_NESTED",
  ZNested: "WORKBENCH_Z_NESTED",
  DefauldItem: "WORKBENCH_NONE"
};

export const DraggableFixtureItems = {
  SHELF: "SHELF",
  BASKET: "BASKET",
  PEGBOARD: "PEGBOARD",
  CROSSBAR: "CROSSBAR"
};

export const DraggableFixtureClipboardItems = {
  SHELF: "SHELF_CLIPBOARD",
  BASKET: "BASKET_CLIPBOARD",
  PEGBOARD: "PEGBOARD_CLIPBOARD",
  CROSSBAR: "CROSSBAR_CLIPBOARD"
};

export const DraggableClipboardItems = {
  YCapped: "Y_CAPPED_CLIPBOARD",
  YNested: "Y_NESTED_CLIPBOARD",
  ZNested: "Z_NESTED_CLIPBOARD",
  DefauldItem: "NONE_CLIPBOARD"
};

export const DraggableClipboardFixtureSkuItems = {
  FixtureYCapped: "FIXTURE_Y_CAPPED_CLIPBOARD",
  FixtureYNested: "FIXTURE_Y_NESTED_CLIPBOARD",
  FixtureZNested: "FIXTURE_Z_NESTED_CLIPBOARD",
  FixtureDefauldItem: "FIXTURE_NONE_CLIPBOARD",
};

export const DraggableClipboardFixtureItems = {
  FixtureShelfItem: "FIXTURE_SHELF_CLIPBOARD",
  FixtureBasketItem: "FIXTURE_BASKET_CLIPBOARD",
  FixturePegboardItem: "FIXTURE_PEGBOARD_CLIPBOARD",
  FixtureCrossbarItem: "FIXTURE_CROSSBAR_CLIPBOARD"
}

export const DraggableSkuClipboardItems = {
  ...DraggableClipboardItems,
  ...DraggableClipboardFixtureSkuItems,
  ...DraggableClipboardFixtureItems
}

export const SEGMENT_WIDTH = 48;
export const ZOOM_MINIMAP_HEIGHT = 67;
export const DISPLAY_HEIGHT = 8;

export const ShelfHighlightColors = {
  default: "rgb(0, 0, 0)",
  disabled: "rgba(0, 0, 0, 0.2)",
  dashed: "rgba(0, 0, 0, 0.4)",
  crossbar: "rgba(117, 117, 117, 1)",
  pegboard: "transparent",
  selected: "rgb(23,209,164)",
  specialty: "#CE0919"
};

export const warningColor = "#ED2626";

export const HORIZONTAL_SPACING = 0.2;
export const VERTICAL_SPACING = 0.5;
export const CLIPBOARD_HORIZONTAL_OFFSET = 250;
export const CLIPBOARD_TOP_OFFSET = 10;
export type DrawerClassKey =
  "withHighlightAndFiltersDrawers" |
  "withHighlightAndLegendDrawers" |
  "withFiltersAndLegendDrawers" |
  "withHighlightDrawer" |
  "withFiltersDrawer" |
  "withThreeDrawers" |
  "withLegendDrawer";

export const DEFAULT_TEMPLATE = {
  z: 0,
  is_dummy: true,
  is_base_shelf: false,
  is_display_only: false,
  is_merchandisable: false,
};

export const BASKET_TEMPLATE = {
  ...DEFAULT_TEMPLATE,
  type: FIXTURE_TYPES.BASKET,
  height: 0.25,
  width: 48,
  shelf_slope: 0,
}

export const SHELF_TEMPLATE = {
  ...DEFAULT_TEMPLATE,
  type: FIXTURE_TYPES.SHELF,
  width: 48,
  height: 1.5,
  shelf_slope: 0,
  grille_height: 0,
}

export const PEGBOARD_TEMPLATE = {
  ...DEFAULT_TEMPLATE,
  id: 0,
  fixture_id: "0467507650",
  x: 0,
  y: 7.5,
  z: 0,
  width: 46.75,
  height: 0,
  depth: 0.1,
  grille_height: 0,
  type: "PEGBOARD",
  segment: 0,
  shelf_slope: 0,
}

export const SHELF_SLOPE = -20.9;

export const PEGBOARD_X_OFFSET = 0.314961;
export const PEGBOARD_Y_OFFSET = 0.0787402;
export const PEGGABLE_CODES_TO_DROP = [0, 6, 12, 18, 24];

export const CROSSBAR_TEMPLATE = {
  ...DEFAULT_TEMPLATE,
  id: 0,
  fixture_id: "0954766",
  x: 0,
  y: 99.31,
  z: 0,
  width: 48,
  height: 1.5,
  depth: 0.5,
  type: "CROSSBAR",
  grille_height: 0,
  segment: 0,
  shelf_slope: 0,
}

export const fixtureTypeToTranslationKey = {
  [DraggableFixtureItems.SHELF]: "postVisualizer.shelfCaption",
  [DraggableFixtureItems.BASKET]: "postVisualizer.basketCaption",
  [DraggableFixtureItems.PEGBOARD]: "postVisualizer.pegboardCaption",
  [DraggableFixtureItems.CROSSBAR]: "postVisualizer.crossbarCaption",
  [DraggableClipboardFixtureItems.FixtureShelfItem]: "postVisualizer.shelfCaption",
  [DraggableClipboardFixtureItems.FixtureBasketItem]: "postVisualizer.basketCaption",
  [DraggableClipboardFixtureItems.FixturePegboardItem]: "postVisualizer.pegboardCaption",
  [DraggableClipboardFixtureItems.FixtureCrossbarItem]: "postVisualizer.crossbarCaption"
}

export const removeSingleFixtureToTranslationKey = {
  [DraggableFixtureItems.SHELF]: "postVisualizer.rightClickMenu.removeShelf",
  [DraggableFixtureItems.BASKET]: "postVisualizer.rightClickMenu.removeBasket",
  [DraggableFixtureItems.CROSSBAR]: "postVisualizer.rightClickMenu.removeCrossbar",
  [DraggableFixtureItems.PEGBOARD]: "postVisualizer.rightClickMenu.removePegboard",
}

export const removeMultipleFixtureToTranslationKey = {
  [DraggableFixtureItems.SHELF]: "postVisualizer.rightClickMenu.removeShelfs",
  [DraggableFixtureItems.BASKET]: "postVisualizer.rightClickMenu.removeBaskets",
  [DraggableFixtureItems.CROSSBAR]: "postVisualizer.rightClickMenu.removeCrossbars",
  [DraggableFixtureItems.PEGBOARD]: "postVisualizer.rightClickMenu.removePegboards",
}

export const fixtureTypeForAlertTranslationKey = {
  [DraggableFixtureItems.SHELF]: "postVisualizer.alert.shelf",
  [DraggableFixtureItems.BASKET]: "postVisualizer.alert.basket",
  [DraggableFixtureItems.PEGBOARD]: "postVisualizer.alert.pegboard",
}

export const eslFixtures = [FIXTURE_TYPES.PEGBOARD, FIXTURE_TYPES.CROSSBAR, FIXTURE_TYPES.FLATBAR];

export enum FIXTURE_Z_INDEX {
  PEGBOARD = 0,
  SHELF = 2,
  BASKET = 1000
}

export enum TooltipType {
  protoC = "protoC",
  peggable = "peggable",
  crossmerch = "crossmerch",
  extendedAssortment = "extendedAssortment"
}

export const tooltipTypeToCaption = {
  [TooltipType.protoC]: "postVisualizer.tooltip.protoC",
  [TooltipType.peggable]: "postVisualizer.tooltip.peggable",
  [TooltipType.crossmerch]: "postVisualizer.tooltip.crossMerch",
  [TooltipType.extendedAssortment]: "postVisualizer.tooltip.extendedAssortment"
}

export const CLIPBOARD_WIDTH = 300;

export const drawersCombination = {
  withThreeDrawers: {
    lg: 892,
    md: 833,
    sm: 813
  },
  withHighlightAndFiltersDrawers: {
    lg: 672,
    md: 613,
    sm: 593
  },
  withHighlightAndLegendDrawers: {
    lg: 620,
    md: 561,
    sm: 541
  },
  withFiltersAndLegendDrawers: {
    lg: 492,
    md: 492,
    sm: 492
  },
  withHighlightDrawer: {
    lg: 400,
    md: 341,
    sm: 321
  },
  withFiltersDrawer: {
    lg: 272,
    md: 272,
    sm: 272
  },
  withLegendDrawer: {
    lg: 220,
    md: 220,
    sm: 220
  }
};

export const BASKET_PARAMS = [
  {
    label: "postVisualizer.rightClickMenu.Basket",
    grille: 6,
    shelfSlope: 0,
    id: 1,
  },
  {
    label: "postVisualizer.rightClickMenu.WireShelf",
    grille: 2,
    shelfSlope: 0,
    id: 2,
  },
  {
    label: "postVisualizer.rightClickMenu.slopedBasket",
    grille: 6,
    shelfSlope: -20.9,
    id: 3,
  },
];

export const SNACKBAR_WIDTH = 48;
export const SNACKBAR_GAP = 8;
export const SNACKBAR_RIGHT_OFFSET = 20;
export const Z_INDEX_SNACKBAR = 1000;
export const Z_INDEX_FOOTER = {
  DEFAULT: 99999,
  EDIT_MODE: 9,
};

export const WORKBENCH_EXTRA_HEIGHT = 111;
export const WORKBENCH_HEIGHT = 177;
export const WORKBENCH_MARGIN_TOP = 12;
export const WORKBENCH_MARGIN_BOTTOM = 24;
export const WORKBENCH_FACING_SPACE = 48;
