import React from 'react';
import jsonpatch from 'fast-json-patch';

// types
import type { Context, Props, State, ViewAndEditContext } from './view.types';
import { FacingGroup } from '../state/job/types';

export const viewContext = React.createContext<Context>(undefined!);
export const viewAndEditContext = React.createContext<ViewAndEditContext>(undefined!);

export class ViewProvider extends React.Component<Props, State> {

  static viewHelperFunc() {

  }

  constructor(props: Props) {
    super(props);
   // this.addPatch = this.addPatch.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.setViewValues = this.setViewValues.bind(this);
  //  this.initFacingGroups = this.initFacingGroups.bind(this);
  //  this.deleteFacingGroup = this.deleteFacingGroup.bind(this);
    this.openReturnToReviewModal = this.openReturnToReviewModal.bind(this);
    this.setEditorRedirectFromCompare = this.setEditorRedirectFromCompare.bind(this);
    this.openExitWorkbenchModal = this.openExitWorkbenchModal.bind(this);
 //   this.closeReturnToReviewModal = this.closeReturnToReviewModal.bind(this);

    this.state = {
      context: {
        patch: props?.patch || [],
        editMode: props?.editMode || false,
        facingGroups: props?.facingGroups || {},
        locatedFixtures: props?.locatedFixtures || [],
        returnToReviewModal: props?.returnToReviewModal || false,
        exitWorkbenchModal: props?.exitWorkbenchModal || false,
        editorRedirectFromCompare: false,
    //    addPatch: this.addPatch,
        setEditMode: this.setEditMode,
        setViewValues: this.setViewValues,
     //   initFacingGroups: this.initFacingGroups,
     //   deleteFacingGroup: this.deleteFacingGroup,
        openReturnToReviewModal: this.openReturnToReviewModal,
        setEditorRedirectFromCompare: this.setEditorRedirectFromCompare,
        openExitWorkbenchModal: this.openExitWorkbenchModal
      //  closeReturnToReviewModal: this.closeReturnToReviewModal,
      },
    };
  }

  // addPatch(value: Record<string, unknown>) {
  //   this.setState((prevState: State) => ({
  //     context: {
  //       ...prevState.context,
  //       patch: prevState.context.patch.concat(value),
  //     },
  //   }));
  // }

  // initFacingGroups(fg: Array<FacingGroup>) {
  //   const { facingGroups } = this.state.context;
  //   if (!Object.keys(facingGroups).length) {
  //     this.setState((prevState: State) => ({
  //       context: {
  //         ...prevState.context,
  //         facingGroups: fg.reduce((acc, facingGroup) => {
  //           acc[facingGroup.id] = facingGroup;
  //           return acc;
  //         }, {} as Record<string, FacingGroup>),
  //       },
  //     }));
  //   }
  // }

  // deleteFacingGroup(id: string) {
  //   const { facingGroups } = this.state.context;
  //   const patch = [
  //     { op: "remove", path: `/${id}`,},
  //   ];

  //   // console.log(, jsonpatch.escapePathComponent(path: string));

  //   // @ts-expect-error
  //   const newFacingGroups = jsonpatch.applyPatch(facingGroups, patch, false, false).newDocument;
  //   this.setState((prevState: State) => ({
  //     context: {
  //       ...prevState.context,
  //       patch: prevState.context.patch.concat(patch),
  //       facingGroups: newFacingGroups,
  //     },
  //   }));
  // }

  // closeReturnToReviewModal() {
  //   this.setState((prevState: State) => ({
  //     context: {
  //       ...prevState.context,
  //       returnToReviewModal: false,
  //     },
  //   }));
  // }

  setViewValues(values: Record<string, unknown>) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        ...values,
      },
    }));
  }

  setEditMode(value: boolean) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        editMode: value,
      },
    }));
  }

  openReturnToReviewModal() {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        returnToReviewModal: true,
      },
    }));
  }

  openExitWorkbenchModal() {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        exitWorkbenchModal: true,
      },
    }));
  }

  setEditorRedirectFromCompare(value: boolean) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        editorRedirectFromCompare: value,
      },
    }));
  }



  render(): JSX.Element {
    return (
      <viewContext.Provider value={this.state.context}>
        {this.props.children}
      </viewContext.Provider>
    );
  }
}

export default ViewProvider;
