import { ActionType, getType } from "typesafe-actions";

import { actions } from "./actions";
import { VendorPegboardState, VendorPegbordAPIItem, VendorPegbordItem } from "./types";

const INITIAL_STATE: VendorPegboardState = {
  pegboards: {}
};

const processVendorPegboardAPIResponse = (vendorPegboards: {
    [key: string]: Array<VendorPegbordAPIItem>
  }) => {
  return Object.keys(vendorPegboards).reduce((acc, pegboardGroup) => {
    acc[pegboardGroup] = vendorPegboards[pegboardGroup].map(pItem => {
      const {
        pegboard_config_id: pegboardConfigId,
        start_hole_x: startHoleX,
        start_hole_y: startHoleY,
        fixture_offset_x: fixtureOffsetX,
        fixture_offset_y: fixtureOffsetY,
        obstructed_rows: obstructedRows,
        ...rest
      } = pItem;
      return {
        ...rest,
        pegboardConfigId,
        startHoleX,
        startHoleY,
        fixtureOffsetX,
        fixtureOffsetY,
        obstructedRows
      };
    });

    return acc;
  }, {} as {[key: string]: Array<VendorPegbordItem>});
}

export function vendorPegboard(
  state: VendorPegboardState = INITIAL_STATE,
  action: ActionType<typeof actions>
): VendorPegboardState {
  switch (action.type) {
    case getType(actions.getVendorPegboards.success): {
      return { pegboards: processVendorPegboardAPIResponse(action.payload) };
    }
    default:
      return state;
  }
}
