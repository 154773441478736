import { ActionType, getType } from "typesafe-actions";

import { actions } from "./actions";
import { ProtoLinearState } from "./types";

const INITIAL_STATE: ProtoLinearState = [
    {size:"A", width:[0], height:[0], depth:[0], skuCount:0, is_step_up: false},
    {size:"B", width:[0], height:[0], depth:[0], skuCount:0, is_step_up: false},
    {size:"C", width:[0], height:[0], depth:[0], skuCount:0, is_step_up: false},
    {size:"D", width:[0], height:[0], depth:[0], skuCount:0, is_step_up: false}
];

export function protoLinearList(
  state: ProtoLinearState = INITIAL_STATE,
  action: ActionType<typeof actions>
): ProtoLinearState {
  switch (action.type) {
    case getType(actions.getProtoLinear.success):{
        return action.payload;
    }
    default:
      return state;
  }
}