import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const Languages = ['en', 'fr'];
let fallbackLng;
if (/^fr\b/.test(navigator.language)) {
    fallbackLng = 'fr'
  } fallbackLng = sessionStorage.getItem("i18nextLng") || 'en'

// @ts-ignore: Unreachable code error
i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(Backend)
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: fallbackLng,
        detection: {
            order: ['navigator']
        },
        whitelist: Languages,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
