import { actions as apiActions } from "../api/actions";
import { ActionType, getType } from "typesafe-actions";

import { actions } from "./actions";
import type { JobState, JobsState, PlanogramState, PlanogremFilterState } from '../job/types';

export const INITIAL_JOB_STATE: JobState = {};
export const INITIAL_JOBS_STATE: JobsState = [];
const INITIAL_PLANOGRAM_STATE: PlanogramState = {};
export const INITIAL_PLANOGRAM_FILTER_STATE: PlanogremFilterState = {
  filterInfo: 'None',
  filter: [],
};

export function jobs(
  state: JobsState = INITIAL_JOBS_STATE,
  action: ActionType<typeof apiActions | typeof actions>
): JobsState {
  switch (action.type) {
		case getType(actions.setInitialJobs):
      return INITIAL_JOBS_STATE;
    case getType(apiActions.getOptimizationJobLimit.success):
    case getType(apiActions.aggregatedJobs.success):
      return action.payload;
    case getType(apiActions.saveNewEditedPog.success):
    case getType(apiActions.updateEditedPog.success):
    case getType(apiActions.flipPog.success):
      return [action.payload.job].concat(state.filter(job => job.job_id !== action.payload.job.job_id));
  }
  return state;
}

export function job(
  state: JobState = INITIAL_JOB_STATE,
  action: ActionType<typeof apiActions | typeof actions>
) {
  switch (action.type) {
    case getType(actions.setInitialJob): {
      return INITIAL_JOB_STATE;
    }
    case getType(apiActions.launchOptimizationJob.success):
      return {
        ...action.payload,
        type: "optimized",
        timeOfLastCall: new Date(),
        numberOfPolls: 0,
      };
    case getType(apiActions.launchOptimizationJob.failure):
      return state;
    case getType(apiActions.getOptimizationStatus.success):
      return {
        ... action.payload,
        type: "optimized",
        numberOfPolls: state.numberOfPolls ? state.numberOfPolls + 1 : 1,
        timeOfLastCall: new Date(),
      };
    case getType(apiActions.getOptimizationStatus.failure):
      return {
        ...state,
        type: "optimized",
        numberOfPolls: state.numberOfPolls ? state.numberOfPolls + 1 : 1,
        timeOfLastCall: new Date(),
      };
  }
  return state;
}

export function planogramFilter(
  state: PlanogremFilterState = INITIAL_PLANOGRAM_FILTER_STATE,
  action: ActionType<typeof actions>
): PlanogremFilterState {
  switch(action.type) {
    case getType(actions.setFilterInfo):
      return {
        ...state,
        filterInfo: action.payload,
      };
    case getType(actions.setFilter): {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default:
      return state;
  }
}

////////////////////////////METRIC FOR JOBID
export const INITIAL_METRIC_STATE: MetricJobResponse = {
  bincap: {},
  facings: {},
  q1f: {},
  total_commitments: {},
  wos: {},
  fill_trips: {},
};

export interface ObjectWithDynamicKeys {
  [key: string]: number;
}
type MetricJobResponse = {
  bincap: ObjectWithDynamicKeys | {},
  facings: ObjectWithDynamicKeys | {},
  q1f: ObjectWithDynamicKeys | {},
  total_commitments: ObjectWithDynamicKeys | {},
  wos: ObjectWithDynamicKeys | {},
  fill_trips: ObjectWithDynamicKeys | {},
};

export function metricJob(
  state: MetricJobResponse = INITIAL_METRIC_STATE,
  action: ActionType<typeof apiActions>
): MetricJobResponse {
  switch (action.type) {
    case getType(apiActions.getMetricsForJobId.success):
    case getType(apiActions.getEditedMetricsProduct.success):
      return action.payload;
    case getType(apiActions.getMetricsForJobId.failure):
    case getType(apiActions.getEditedMetricsProduct.failure):
        return INITIAL_METRIC_STATE;
  }
  return state;
}

////////////////////////////////////////////////////////////////////////////SOLVER OUTPUT

type SolverOutputResponse = {

};

export const INITIAL_SOLVER_OUTPUT_STATE = {}
export function solverOutput(
  state: any = INITIAL_SOLVER_OUTPUT_STATE,
  action: ActionType<typeof apiActions>
): SolverOutputResponse {
  switch (action.type) {
    case getType(apiActions.getOptimizationSolverOutput.failure):
    case getType(apiActions.getEditedSolverOutput.failure):
      return INITIAL_SOLVER_OUTPUT_STATE;
    case getType(apiActions.getOptimizationSolverOutput.request):
    case getType(apiActions.getEditedSolverOutput.request): {
      if (
        action.payload === state.job_id ||
        action.payload === state.parent_job_id
      ) {
        return state;
      }
      return INITIAL_SOLVER_OUTPUT_STATE;
    }
    case getType(apiActions.getOptimizationSolverOutput.success):
    case getType(apiActions.getEditedSolverOutput.success):
      return action.payload;
    case getType(apiActions.flipPog.success):
      return action.payload.solver_output;
    case getType(apiActions.saveNewEditedPog.success):
    case getType(apiActions.updateEditedPog.success):
      return action.payload.solver_output;
  }
  return state;
}
