import { ActionType, getType } from "typesafe-actions";
import { actions } from "../api/actions";
import { PackagingState } from "../job/types";

export const INITIAL_STATE = {};

export function packaging(
  state: PackagingState = INITIAL_STATE,
  action: ActionType<typeof actions>
): PackagingState {
  switch (action.type) {
    case getType(actions.getProductsPackaging.success): {
      return action.payload;
    }
    default:
      return state;
  }
}
