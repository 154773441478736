import {
  SilentRequest,
  AuthenticationResult,
  IPublicClientApplication,
  PublicClientApplication,
 } from "@azure/msal-browser";

import { msalConfig } from "../authConfig";
import { ssoSilent } from "../authConfig";

export class msalServiceClass {
  _msalInstance: IPublicClientApplication;
  _accessToken: string;

  constructor(msalInstance: IPublicClientApplication) {
    this._msalInstance = msalInstance;
    this._accessToken = '';
  }

  setMsalInstance(instance: IPublicClientApplication) {
    this._msalInstance = instance;
  }

  getMsalInstance() {
    return this._msalInstance;
  }

  setAccessToken(accessToken: string) {
    this._accessToken = accessToken;
  }

  getAccessToken() {
    return this._accessToken;
  }

  getAccount() {
    if (this._msalInstance) {
      const accounts = this._msalInstance.getAllAccounts();
      if (accounts.length) {
        const account = accounts[0];
        if (account) return account;
      }
    }
    return null;
  }

  async logout() {
    const account = this.getAccount();
    await this._msalInstance.logoutRedirect({
      account,
    });
  }

  async checkAccessToken() {
    try {
      if (this._msalInstance) {
        const account = this.getAccount();
        if (account) {
          const responseAcquireTokenSilent: AuthenticationResult = await this._msalInstance.acquireTokenSilent({
            ...ssoSilent,
            account,
          } as SilentRequest);
          const { accessToken } = responseAcquireTokenSilent;
          if (this._accessToken !== accessToken) {
            this.setAccessToken(accessToken);
          };
          return accessToken;
        }
      }
    } catch (e) {
      console.log('msalHandleAccesToken error', e);
      return null;
    }
  }
}

export default new msalServiceClass(new PublicClientApplication(msalConfig));
