/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

type SubType<Base, Condition> = Pick<
  Base,
  {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
  }[keyof Base]
>;

export function normalizeData<
  T extends Record<string, any>,
  K extends keyof SubType<T, number | string>
>(data: Array<T>, propertyName = 'id' as K) {
  const newRes = data && data.length>0 && data.reduce(
    (acc, element) => {
      acc[0][element[propertyName]] = element;
      acc[1].push(element[propertyName]);
      return acc;
    },
    ([{}, []] as unknown) as [Record<T[K], T>, T[K][]],
  );
  return newRes;
}
