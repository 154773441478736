import React from 'react';
import update from "immutability-helper";

import { StoreState, apiClientJava } from "../../state";
// types
import type { Context, Props, State, Banners, Banner } from './admin.types';

export const adminContext = React.createContext<Context>(undefined!);

function sortNotificationByOrder(notifications: Banners) {
  const notificationsWithOrder = notifications.filter(notification => notification.order !== null);
  const notificationsWitnOrderNull = notifications.filter(notification => notification.order === null);
  const notificationsWithOrderSort = notificationsWithOrder.sort((a, b) => {
    if (a.order && b.order) {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
    }
    return 0;
  });
  return notificationsWithOrderSort.concat(notificationsWitnOrderNull);
}

function sortExpiredNotifications(notificationsWithoutExpired: Banners, allNotifications: Banners) {
  return allNotifications.filter(object1 => {
    return !notificationsWithoutExpired.some(object2 => {
      return object1.notification_id === object2.notification_id;
    });
  });
}

export class AdminProvider extends React.Component<Props, State> {

  static previewNotifications(notifications: Banners): Banners {
    return sortNotificationByOrder(notifications).slice(0, 3);
  }

  constructor(props: Props) {
    super(props);
    this.addNotification = this.addNotification.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.getAllNotifications = this.getAllNotifications.bind(this);
    this.moveNotifications = this.moveNotifications.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
    this.updateNotification = this.updateNotification.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
    this.setNotificationMode = this.setNotificationMode.bind(this);

    this.state = {
      context: {
        notifications: [],
        expiredNotifications: [],
        notificationMode: false,
        addNotification: this.addNotification,
        getNotifications: this.getNotifications,
        getAllNotifications: this.getAllNotifications,
        moveNotifications: this.moveNotifications,
        removeNotification: this.removeNotification,
        updateNotification: this.updateNotification,
        updateNotifications: this.updateNotifications,
        setNotificationMode: this.setNotificationMode,
      },
    };
  }

  setNotificationMode(mode: Banner | boolean) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        notificationMode: mode,
      }
    }));
  }

  async getNotifications() {
    const response: Banners = await apiClientJava.getAllNotificationBanners("false");
    const result = sortNotificationByOrder(response);
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        notifications: result,
      }
    }));
  }

  async getAllNotifications() {
    const notificationsWithoutExpiredResponse: Banners = await apiClientJava.getAllNotificationBanners("false");
    const allNotificationsResponse: Banners = await apiClientJava.getAllNotificationBanners("true");
    const result = sortNotificationByOrder(notificationsWithoutExpiredResponse);
    const expiredNotificationsResult = sortExpiredNotifications(notificationsWithoutExpiredResponse, allNotificationsResponse)
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        notifications: result,
        expiredNotifications: expiredNotificationsResult,
      }
    }));
  }

  moveNotifications(dragIndex: number, hoverIndex: number) {
    this.setState((prevState: State) => {
      const newNotifications: Banners = update(prevState.context.notifications, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevState.context.notifications[dragIndex]],
        ],
      }).map((notif, index) => ({
        ...notif,
        order: index > 2 ? null : index,
      }));
      return {
        context: {
          ...prevState.context,
          notifications: newNotifications,
        },
      }
    });
  }

  async updateNotification(reqBody: Banner) {
    const notification = await apiClientJava.updateNotificationBanner({
      notificationId: reqBody.notification_id,
      reqBody,
    });
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        notificationMode: false,
        notifications: sortNotificationByOrder(prevState.context.notifications
          .filter(n => n.notification_id !== notification.notification_id)
          .concat(notification))
      },
    }));
  }

  async updateNotifications() {
    const { notifications } = this.state.context;
    await Promise.all(notifications.map(
      notification => apiClientJava.updateNotificationBanner({
        notificationId: notification.notification_id,
        reqBody: notification,
      })
    ));
  }

  async removeNotification(notificationId: string) {
    await apiClientJava.deleteNotificationBanner({ notificationId });
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        notificationMode: false,
        notifications: prevState.context.notifications
          .filter(notification => notification.notification_id !== notificationId)
          .map((notification, index) => ({
            ...notification,
            order: index > 2 ? null : index,
          })),
      },
    }));
  }

  async addNotification(reqBody: Banner) {
    const notification = await apiClientJava.saveNewNotificationBanner({ reqBody });
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        notificationMode: false,
        notifications: sortNotificationByOrder(prevState.context.notifications.concat(notification)),
      },
    }));
  }

  render(): JSX.Element {
    return (
      <adminContext.Provider value={this.state.context}>
        {this.props.children}
      </adminContext.Provider>
    );
  }
}

export default AdminProvider;
