import { v4 as uuidv4 } from "uuid";
import { AisleExtended } from "../components/PogDashboard/PogsTable";

import { DrawerClassKey, FIXTURE_TYPES, FIXTURE_TYPES_NUMBERS, PEGGABLE_CODES_TO_DROP } from "../components/ViewAndEdit/editorConstants";
import { FacingGroup } from "../state/job/types";
import { Halo, HaloPegboard, SelectedHaloPegboard } from "../state/haloPegboard/types";
import { orderMap } from '../utils/constants'

export const generateUniqueId = (): string => {
  return uuidv4();
};

const numberToString = (n: number) => {
  return n.toString().replace('.', '');;
}

export const generateFixtureId = (
  width: number,
  height: number,
  depth: number,
  type: FIXTURE_TYPES,
): string => {
  const dN = numberToString(depth);
  const wN = numberToString(width);
  const hN = numberToString(height);

  return `${FIXTURE_TYPES_NUMBERS[type]}${dN}${wN}${hN}`;
};
export const lightenDarkenColor = (color: string, amount: number) => '#' + color
  .replace(/^#/, '')
  .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16))
    .substr(-2));

export const escapeRegExp = (str: string): string => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const domainRegExp = /^.*\/\/[^\/]+/;

export const matchesDomainURL = (url: string) => {
  if (url && typeof url === 'string' && url.length) {
    return Boolean(url.match(domainRegExp));
  }
  return false;
}

export const domainURLRegExp = (str: string): string => {
  const linkSplit = str.split('/');
  return linkSplit[linkSplit.length - 1];
};

export const whiteSpaceAfterChar = (str: string, char: string = ";"): string => {
  if (!str) {
    return "";
  }

  const regExpPattern = new RegExp(`${char}(?=[^\\s])`, "g");

  return str.replace(regExpPattern, `${char} `);
}

export const getUnits = (value: any, str: string | undefined): string => {
  switch (str) {
    case "Price": {
      return value ? `$${value}` : "-/-";
    }

    case "Weight": {
      if (!value) {
        return "-/-";
      }

      return value < 1 ? "< 1lb" : `${Math.trunc(value)}lbs`;
    }

    case "WOS": {
      return value ? (+value).toFixed(1) : "-/-";
    }

    case "Forecast": {
      if (!value) {
        return "-/-";
      }

      return `${Math.trunc(value)}`;
    }

    default: {
      return value ?? "-/-";
    }
  }
}

export const linkValidation = (link: string) => {
  let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
  return regEx.test(link);
}

export const emailValidation = (email: string) => {
  let regEx = /\S+@\S+\.\S+/;
  return regEx.test(email);
}

export const getClassForOpenedDrawers = (
  isProductHighlightOpened: boolean,
  isFiltersOpened: boolean,
  isLegendOpened: boolean
): DrawerClassKey => {

  let className = "";
  if (isProductHighlightOpened && isFiltersOpened && isLegendOpened) {
    className = "withThreeDrawers";
  } else if (isProductHighlightOpened && isFiltersOpened && !isLegendOpened) {
    className = "withHighlightAndFiltersDrawers";
  } else if (isProductHighlightOpened && isLegendOpened && !isFiltersOpened) {
    className = "withHighlightAndLegendDrawers";
  } else if (isFiltersOpened && isLegendOpened && !isProductHighlightOpened) {
    className = "withFiltersAndLegendDrawers";
  } else if (isFiltersOpened && !isLegendOpened && !isProductHighlightOpened) {
    className = "withFiltersDrawer";
  } else if (isLegendOpened && !isFiltersOpened && !isProductHighlightOpened) {
    className = "withLegendDrawer";
  } else if (isProductHighlightOpened && !isLegendOpened && !isFiltersOpened) {
    className = "withHighlightDrawer";
  }

  return className as DrawerClassKey;
}

export const isNatural = (s: number | null) => {
  if (s === null) return false;
  const n = parseInt("" + s, 10);//to convert number to string
  return n > 0 && n.toString() === (s + "");
}

export const getLabelSupportData = (row: any) => {
  const defaultObj = { color: 'RED', value: "" };
  if (!row.label_status) return defaultObj;
  const { status_esl, status_reg } = row.label_status;

  if (!status_esl || !status_reg) {
    return defaultObj;
  }
  const isSpecialColor = (color: string) => ['RED', 'ORANGE'].includes(color);

  // Check if both status_esl and status_reg are ORANGE or RED
  if (isSpecialColor(status_esl) && isSpecialColor(status_reg)) {
    return { color: (status_esl === 'RED' && status_reg === 'ORANGE') ? 'ORANGE' : status_esl, value: '-' };
  }

  // Determine the color based on orderMap priority
  const chosenColor = orderMap[status_esl] < orderMap[status_reg] ? status_esl : status_reg;
  return {
    color: chosenColor,
    value: status_esl === status_reg ? 'Reg/ESL' : (chosenColor === status_esl ? 'ESL' : 'Reg')
  };
};

export const isRotate = (orientationCode: number) => {
  switch (orientationCode) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 9:
    case 11:
    case 13:
    case 15:
    case 17:
    case 19:
    case 21:
    case 23:
      return true;
    default:
      return false;
  }
}

export const getFacing = (facingOrientationCode: number) => {
  let facing!: string;
  switch (facingOrientationCode) {
    case 0:
    case 6:
    case 12:
    case 18:
      facing = "postVisualizer.rightClickMenu.frontFaced";
      break;
    case 1:
    case 7:
    case 13:
    case 19:
      facing = "postVisualizer.rightClickMenu.frontFaced*";
      break;
    case 2:
    case 8:
    case 14:
    case 20:
      facing = "postVisualizer.rightClickMenu.sideFaced";
      break;
    case 3:
    case 9:
    case 15:
    case 21:
      facing = "postVisualizer.rightClickMenu.sideFaced*";
      break;
    case 4:
    case 10:
    case 16:
    case 22:
      facing = "postVisualizer.rightClickMenu.topFaced";
      break;
    case 5:
    case 11:
    case 17:
    case 23:
      facing = "postVisualizer.rightClickMenu.topFaced*";
      break;
  }
  return facing;
}; 

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const mm = today.getMonth() + 1;
  const dd = today.getDate();
  const month = mm < 10 ? `0${mm}` : mm;
  const date = dd < 10 ? `0${dd}` : dd;

  return `${year}-${month}-${date}`;
}



export const openFile = async (blob: Blob, fileName: string) => {
  const newBlob = new File([blob], fileName, {type: "application/pdf"})
  const dataUrl = window.URL.createObjectURL(newBlob);
  window.open(dataUrl, '_blank');
  window.URL.revokeObjectURL(dataUrl);
}

export const downloadFile = async (blob: Blob, fileName: string) => {
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(downloadUrl);
}

export const buildLanguageString = (str: string, ...params: Array<string>) =>
  params.reduce((result, param, i) => result.replace(`#${i + 1}`, param), str);

export const getCurrentBreakpoint = (
  greaterThanMid: boolean,
  smallToMid: boolean
) => greaterThanMid ? "lg" : smallToMid ? "md" : "sm";

export const isPeggableSku = (facingGroup: FacingGroup) => {
  const { packaging: { peggable, pegholes }, orientation_code: code } = facingGroup;
  const isAllowedCode = PEGGABLE_CODES_TO_DROP.includes(code);
  const isPegholes = pegholes && Boolean(pegholes.length);

  return peggable && isPegholes && isAllowedCode;
}

export const checkInValidDeal = (deal: string) => {
  const regex = /^\d{4}-\d*/;
  return deal && !regex.test(deal);
};

export const pegboardHaloSetting: HaloPegboard = {
  defaultPadding: {
    id: 'defaultPadding',
    title: "preAssortment.optimizationParameters.defaultPadding",
    inter_sku_spacing_pegboard: 0.1,
    inter_facing_vertical_spacing_pegboard: 0.25,
    inter_facing_spacing_pegboard: 0.1,
  },
  tightenedPadding: {
    id: 'tightenedPadding',
    title: "preAssortment.optimizationParameters.tightenedPadding",
    inter_sku_spacing_pegboard: 0,
    inter_facing_vertical_spacing_pegboard: 0,
    inter_facing_spacing_pegboard: 0,
  },
  loosePadding: {
    id: 'loosePadding',
    title: "preAssortment.optimizationParameters.loosePadding",
    inter_sku_spacing_pegboard: 0.1,
    inter_facing_vertical_spacing_pegboard: 0.5,
    inter_facing_spacing_pegboard: 0.1,
  },
};

export const createKeyByString = ((value: string): string => value.toLowerCase().replaceAll(' ', ''));
