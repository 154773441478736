import { ActionType, getType } from "typesafe-actions";

import type { FixturesState, FixturesNotchesHolesState } from "./fixtures.types";
import { actions } from "./fixtures.actions";

const INITIAL_STATE_FIXTURES: FixturesState = [];
const INITIAL_STATE_FIXTURES_NOTCHES_HOLES: FixturesNotchesHolesState = [];

export function fixtures(
  state: FixturesState = INITIAL_STATE_FIXTURES,
  action: ActionType<typeof actions>
): FixturesState {
  switch (action.type) {
    case getType(actions.getFixtures.success):
      return action.payload;
  }

  return state;
}

export function fixturesNotchesHoles(
  state: FixturesNotchesHolesState = INITIAL_STATE_FIXTURES_NOTCHES_HOLES,
  action: ActionType<typeof actions>
): FixturesNotchesHolesState {
  switch (action.type) {
    case getType(actions.getFixturesNotchesHoles.success):
      return action.payload;
  }

  return state;
}
