import { AssortmentItemOptimized, Checkboxes, MetricJob } from "../../types/models";
import { actions } from "./actions";
import { actions as apiActions } from "../api/actions";
import { ActionType, getType } from "typesafe-actions";
import { getBaseAssortment, mergeCurrentStateProduct } from '../../helpers/data';
import {ApiAssortment, ApiForecastInfo} from '../../types/api';

export type AssortmentState = {
  baseAssortment?: AssortmentItemOptimized[];
  removedSkus: string[];
  customFields: Checkboxes;
  assortmentApi?: ApiAssortment;
  facings: TotalFacings,
  currentMetricInfo: MetricJob,
  currentStateProduct: MetricJob,
  forecastInfo: Array<ApiForecastInfo>,
  totalCommitments: Record<string, number>
};

export type TotalFacings = {
  currentTotalFacings: number | null;
  totalFacingsByJobId: {[key: string]: number}
}

export const defaultCustomizeValues: Checkboxes = {
  description: true,
  corpStatus: true,
  storeStatus: true,
  totalCommitments: true,
  primary: true,
  secondary: true,
  crossMerch: true,
  extendedAssortment: true,
  shUnitAnnul: true,
  sh$Annul: true,
  shPromoPercent: true,
  sfUnitAnnul: true,
  sf$Annul: true,
  sfPromoPercent: true,
  campsUnitAnnul: true,
  lostSales$$: true,
  lostSalesUnits: true,
  avgReg: true,
  avgRegPromo: true,
  avgRegIss: true,
  avgRegPromoIss: true,
  peakReg: true,
  peakRegPromo: true,
  peakRegIss: true,
  peakRegPromoIss: true,
  dgm: true,
  dmoq: true,
  promotable: true,
  bincap: true,
  q1f: true,
  fillTrips: true,
  wos: true,
  historic: true,
  forecast: true,
  ofPromo: true,
  facings: true,

  //OPTIMIZED
  bincapOptimized: true,
  newMinTargetBincapOptimized: true,
  newMaxTargetBincapOptimized: true,
  totalFacingsOptimized: true,
  horzFacingsOptimized: true,
  vertFacingsOptimized: true,
  q1fOptimized: true,
  cappedFacingOptimized: true,
  cappedQ1fOptimized: true,
  fillTripsOptimized: true,
  wosOptimized: true,


  //CHANGES
  bincapChanges: true,
  fillTripsChanges: true,
  wosChanges: true,

  minTargetBincap: true,
  maxTargetBincap: true
}

const defaultMetrics = {
  bincap: {},
  facings: {},
  fill_trips: {},
  q1f: {},
  total_commitments: {},
  wos: {},
};

const INITIAL_STATE: AssortmentState = {
  removedSkus: [],
  currentMetricInfo: defaultMetrics,
  customFields: defaultCustomizeValues,
  facings: {
    currentTotalFacings: null,
    totalFacingsByJobId: {}
  },
  currentStateProduct: defaultMetrics,
  forecastInfo: [],
  totalCommitments: {}
};

export function assortment(
  state: AssortmentState = INITIAL_STATE,
  action: ActionType<typeof actions | typeof apiActions>
): AssortmentState {
  switch (action.type) {
    case getType(actions.removeItems):
      return {
        ...state,
        removedSkus: [...state.removedSkus, ...action.payload],
      };

    case getType(actions.restoreItems):
      return {
        ...state,
        removedSkus: state.removedSkus.filter(
          (s) => !action.payload.includes(s)
        ),
      };

    case getType(apiActions.getAssortment.success):
      const { assortmentApi,
        totalCommitments,
        forecastInfo,
        baseAssortmentWithTargetBincap,
        metricsProductStoreInfo,
        currentTotalFacings
      } = getBaseAssortment(action.payload, state.currentStateProduct);
      return {
        ...state,
        baseAssortment: baseAssortmentWithTargetBincap,
        removedSkus: [],
        assortmentApi,
        currentMetricInfo: metricsProductStoreInfo,
        facings: {
          ...state.facings,
          currentTotalFacings
        },
        forecastInfo,
        totalCommitments
      };

    case getType(apiActions.getCurrentStateProductsAssortment.success):
      const mappedAst = mergeCurrentStateProduct(state.baseAssortment || [], action.payload);
      return {
        ...state,
        baseAssortment: mappedAst,
        currentStateProduct: action.payload,
      };

    case getType(apiActions.getMetricsForJobId.success):
    case getType(apiActions.getEditedMetricsProduct.success):
      const totalFacings = Object.values(action.payload.facings).reduce((acc, val) => acc + val);

      return {
        ...state,
        facings: {
          ...state.facings,
          totalFacingsByJobId: {
            ...state.facings.totalFacingsByJobId,
            [action.payload.jobId]: totalFacings
          }
        }
      };

    case getType(actions.changeFacings): {
      if (state.baseAssortment === undefined) return state;
      else {
        const { sku, facings } = action.payload;
        const facingsChangeIndex = state.baseAssortment.findIndex(
          (a) => a.sku === sku
        );
        if (facingsChangeIndex === -1) return state;
        else
          return {
            ...state,
            baseAssortment: [
              ...state.baseAssortment.slice(0, facingsChangeIndex),
              {
                ...state.baseAssortment[facingsChangeIndex],
                //optimizedFacings: facings,
              },
              ...state.baseAssortment.slice(facingsChangeIndex + 1),
            ],
          };
      }
    }

    case getType(actions.setFacingsLock): {
      if (state.baseAssortment === undefined) return state;

      const { sku, locked } = action.payload;
      const lockChangeIndex = state.baseAssortment.findIndex(
        (a) => a.sku === sku
      );
      if (lockChangeIndex === -1) return state;
      else
        return {
          ...state,
          baseAssortment: [
            ...state.baseAssortment.slice(0, lockChangeIndex),
            {
              ...state.baseAssortment[lockChangeIndex],
              //facingsLocked: locked,
            },
            ...state.baseAssortment.slice(lockChangeIndex + 1),
          ],
        };
    }

    case getType(actions.setCustomFields): {
      const { customFields } = action.payload;

      return {
        ...state,
        customFields: customFields
      };
    }

    case getType(actions.setToInitialAssortment): {
      return INITIAL_STATE;
    }

    case getType(actions.setTargetBincap): {
      if (state.baseAssortment === undefined) {
        return state;
      }

      const { sku, value, field, postSolver } = action.payload;
      const index = state.baseAssortment.findIndex((a) => a.sku === sku);
      if (index === -1) {
        return state;
      }

      if (postSolver) {
        const prop = field === "minTargetBincap" ? "newMinTargetBincapOptimized" : "newMaxTargetBincapOptimized";
        return {
          ...state,
          baseAssortment: [
            ...state.baseAssortment.slice(0, index),
            ...(state.baseAssortment[index].optimized  ? [
              {
                ...state.baseAssortment[index],
                optimized: {...state.baseAssortment[index].optimized!, [prop]: value }
              }
            ] : [
              {
                ...state.baseAssortment[index]
              }
            ]),
            ...state.baseAssortment.slice(index + 1),
          ],
        };
      }

      return {
        ...state,
        baseAssortment: [
          ...state.baseAssortment.slice(0, index),
          {
            ...state.baseAssortment[index],
            [field]: value,
          },
          ...state.baseAssortment.slice(index + 1),
        ],
      };
    }
  }

  return state;
}
