import { ActionType, getType } from "typesafe-actions";
import { actions } from "../api/actions";
import { ProductsState, ProductsListState } from "../job/types";

export const INITIAL_PRODUCT_STATE = {};
export const INITIAL_PRODUCT_LIST_STATE = [];

export function products(
  state: ProductsState = INITIAL_PRODUCT_STATE,
  action: ActionType<typeof actions>
): ProductsState {
  switch (action.type) {
    case getType(actions.getProductsInfo.success): {
      return action.payload.products;
    }
    default:
      return state;
  }
}

export function productsList(
  state: ProductsListState = INITIAL_PRODUCT_LIST_STATE,
  action: ActionType<typeof actions>
): ProductsListState {
  switch (action.type) {
    case getType(actions.getProductsInfo.success): {
      return action.payload.productsList;
    }
    default:
      return state;
  }
}
