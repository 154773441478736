import { ActionType, getType } from "typesafe-actions";
import { actions } from "./actions";
import { BlockingState, BlockingListState } from "./types";

export const INITIAL_BLOCKING_STATE: BlockingState = {};
export const INITIAL_BLOCKING_LIST_STATE: BlockingListState = [];

export function blocking(
  state: BlockingState = INITIAL_BLOCKING_STATE,
  action: ActionType<typeof actions>
): BlockingState {
  switch (action.type) {
    case getType(actions.getProductsBlocking.success): {
      return action.payload.values;
    }
    default:
      return state;
  }
}

export function blockingList(
  state: BlockingListState = INITIAL_BLOCKING_LIST_STATE,
  action: ActionType<typeof actions>
): BlockingListState {
  switch (action.type) {
    case getType(actions.getProductsBlocking.success): {
      return action.payload.names;
    }
    default:
      return state;
  }
}
