// @ts-nocheck
import queryString from "query-string";
import axios, { AxiosInstance } from "axios";
import * as retryAxios from "retry-axios";
import { normalizeData } from "../helpers/normalize-data";
import msalService from "../services/msalService";
import {getLabelSupportData} from "../helpers/utils"
import { solverOutputGenerateId, jobsGetParams } from "../helpers/data";
import {
  GetOptimizationStatusParams,
  LaunchOptimizationJobParams,
  GetAssortmentParams,
  GetAvailableAislesParams,
  GetCurrentStateParams,
  GetParametersParams,
  SaveDragAndDropEditsParams,
  getOptimizationJobParams,
  getOptimizationJobProtoParams,
  GetSolverOutputParams,
  GetImagesParams,
  OptimalSpaceSegmentParams,
  GetEditedMetricsPogParams,
  GetEditedMetricsProductParams,
  GetEditedSolverOutputParams,
  GetEditedJobParams,
  SaveNewEditedPogParams,
  getFetchPogByStoreParams,
  DeleteNotificationBannerParams,
  SaveNewNotificationBannerParams,
  UpdateNotificationBannerParams,
  AggregatedJobsParams,
  AggregatedSolverOutputParams,
  AggregatedProductMetricsParams,
  AggregatedPogMetricsParams,
  ProductsInfoParams,
  ProductsPackagingParams,
  SaveUserPreferenceParams,
  SaveMyNotesParams,
  ProtoLinearParams,
  DownloadAssortmentExcelFileParam,
  FlipPogParams
} from "../types/requests";
import {
  GetOptimizationStatusResponse,
  OptimizationJobsResponse,
  LaunchOptimizationJobResponse,
  GetAssortmentResponse,
  GetStateResponse,
  GetParametersResponse,
  GetAvailableAislesResponse,
  SaveDragAndDropEditsResponse,
  GetMeticForJobIdResponse,
  SolverOutputResponse,
  GetImagesResponse,
  DatasetInfo,
  GetExtendedMeticForJobIdResponse,
  GetOptimalSpaceSegmentResponse,
  GetEditedMetricsPogResponse,
  GetEditedMetricsProductResponse,
  EditedJobResponse,
  SaveNewEditedPogResponse,
  GetFetchPogByStoreResponse,
  GetAllNotificationBannersResponse,
  DeleteNotificationBannerResponse,
  SaveNewNotificationBannerResponse,
  UpdateNotificationBannerResponse,
  GetAvailableStoresResponseWithAdmin,
  AggregatedJobsResponse,
  AggregatedSolverOutput,
  AggregatedProductMetricsResponse,
  AggregatedPogMetricsResponse,
  ProductsInfoResponse,
  ProductsPackagingResponse,
  SaveUserPreferenceResponse,
  GetUserPreferenceResponse,
  SaveMyNotesResponse,
  ProtoLinearResponse,
  GetMatchedPegboardConfigResponse,
} from "../types/responses";

import type { GetFixturesResponse, GetFixturesNotchesHolesResponse } from "../state/fixtures/fixtures.types";

import type {
  BlockingParams,
  BlockingResponse,
} from "../state/blocking/types";
import { APIMSUBSCRIPTION_DEFAULT_VALUE } from "../utils/constants";
import { getConfig } from "../utils/getConfig";
import { VendorPegbordAPIItem } from '../state/vendorPegboard/types';
import { reqBody } from "../components/ProtoPog/Provider/protopog.types";

const REQUEST_TIMEOUT = 60000;
const APIMSUBSCRIPTION = getConfig('APIMSUBSCRIPTION') || APIMSUBSCRIPTION_DEFAULT_VALUE;

export function createAxiosInstance(apiUrl: string): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: REQUEST_TIMEOUT,
    withCredentials: true,
    headers: {'Ocp-Apim-Subscription-Key': APIMSUBSCRIPTION}
  });

  retryAxios.attach(axiosInstance);
  return axiosInstance;
}

export class ApiClient {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  setAccessToken(accessToken: string) {
    const token = `Bearer ${accessToken}`;
    this.axiosInstance.defaults.headers.common['Authorization'] = token;
  }

  async msalHandleAccesToken() {
    try {
      const accessToken = await msalService.checkAccessToken();
      if (accessToken) {
       this.setAccessToken(accessToken);
      }
    } catch (e) {
      console.log('msalHandleAccesToken error', e);
    }
  }

  /* istanbul ignore next */
  async requestHendlerGet(url: string, data = {}) {
    try {
      await this.msalHandleAccesToken();
      const response = await this.axiosInstance.get(url, data);
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          await msalService.logout();
        }
      }
      throw error;
    }
  }

  async requestHendlerPost(url: string, data = {}) {
    await this.msalHandleAccesToken();
    const response = await this.axiosInstance.post(url, data);
    return response;
  }

  async requestHendlerDelete(url: string, data = {}) {
    await this.msalHandleAccesToken();
    const response = await this.axiosInstance.delete(url, data);
    return response;
  }

  async getAvailableStores(): Promise<GetAvailableStoresResponseWithAdmin> {
    try{
      const url = `auth/`;
      const response = await this.requestHendlerGet(url);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 403) {
          return await msalService.logout()
      }
      throw error;
    }
  }

  async getAvailableAisles(
    params: GetAvailableAislesParams
  ): Promise<GetAvailableAislesResponse> {
    const { storeNumber } = params;

    const url = `/stores/${storeNumber}/aisles`;
    const response = await this.requestHendlerGet(url);
    let { responseData } = response.data;
    responseData = responseData.map((r,i) => {
     let labelSupportValue = getLabelSupportData(r);
     r.status = labelSupportValue.color;
     r.label_support = labelSupportValue.value;
     return r;
    })

    return responseData;
  }

  async getParameters(
    params: GetParametersParams
  ): Promise<GetParametersResponse> {
    const { aisleCode, storeId } = params;
    const url = `/stores/${storeId}/aisles/${aisleCode}/parameters`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async getAssortment(
    params: GetAssortmentParams
  ): Promise<GetAssortmentResponse> {
    const { aisleCode, storeId } = params;
    const urlAssortment = `stores/${storeId}/aisles/${aisleCode}/assortments`;
    let responseAssortment;
    let currentTotalFacings;
    let assortmentApi;
    let metricsProductStoreInfoApi;
    let forecastInfoApi;
    let totalCommitments = {};

    let res = {};
    let processedRes = [];

    try {
      responseAssortment = await this.requestHendlerGet(urlAssortment);
      let { assortmentsInfo, forecastInfo, inventoryInfo, locationInfo, productInfo, metricsProductStoreInfo } = responseAssortment.data.responseData;
      assortmentApi = assortmentsInfo;
      metricsProductStoreInfoApi = metricsProductStoreInfo;
      forecastInfoApi = forecastInfo;

      assortmentsInfo.aisle.forEach((e, i) => {
        res[e] = {};
      });

      [forecastInfo, inventoryInfo, locationInfo, productInfo].forEach((d, i) => {
        d.forEach((item, i) => {
          if (!(item.product_num in res)) {
            res[item.product_num] = {};
          }
          res[item.product_num] = { ...res[item.product_num], ...item };

          res[item.product_num].sku = item.product_num;
        });
      });

      let facing = metricsProductStoreInfo.facings;
      let q1f = metricsProductStoreInfo.q1f;
      let bincap = metricsProductStoreInfo.bincap;
      let wos = metricsProductStoreInfo.wos;
      let ft = metricsProductStoreInfo.fill_trips;

      inventoryInfo.forEach(p => {
        const { product_num, total_item_count } = p;
        if (res[product_num]) {
          res[product_num].total_commitments = total_item_count;
          totalCommitments[product_num] = total_item_count;
        }
      });

      Object.keys(facing).forEach((f, i) => {
        if (res[f]) {
          res[f].facings = facing[f];
        }
      });

      Object.keys(q1f).forEach((q, i) => {
        if (res[q]) {
          res[q].q1f = q1f[q];
        }
      });

      Object.keys(bincap).forEach((b, i) => {
        if (res[b]) {
          res[b].bincap = bincap[b];
        }
      });

      Object.keys(wos).forEach((w, i) => {
        if (res[w]) {
          res[w].wos = wos[w];
        }
      });

      Object.keys(ft).forEach((f, i) => {
        if (res[f]) {
          res[f].fill_trips = ft[f];
        }
      });

      Object.keys(res).forEach((e, i) => {
        processedRes.push(res[e]);
      });

      currentTotalFacings = Object.values(facing).reduce((acc, val) => acc + val, 0);

    } catch (err) {
      console.log(err)
    }

    return { assortment: processedRes, assortmentApi, currentTotalFacings, metricsProductStoreInfo: metricsProductStoreInfoApi, forecastInfo: forecastInfoApi, totalCommitments };
  }

  async getCurrentState(
    params: GetCurrentStateParams
  ): Promise<GetStateResponse> {
    const { storeId, aisleCode, demand, use_iss, use_prom } = params;
    const query = demand ? `?demand=${demand}&use_iss=${use_iss}&use_prom=${use_prom}` : '';
    const url = `/stores/metrics/pog/stores/${storeId}/aisles/${aisleCode}${query}`;

    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async getOptimizedState(
    params: getOptimizationJobProtoParams
  ): Promise<GetStateResponse> {
    const url = `/stores/metrics/pog/jobs/${params.job_id}`

    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async getCurrentStateProducts(
    params: GetCurrentStateParams
  ): Promise<GetProductsStateResponse> {
    const { storeId, aisleCode, demand, use_iss, use_prom } = params;
    const query = demand ? `?demand=${demand}&use_iss=${use_iss}&use_prom=${use_prom}` : '';
    const url = `/stores/metrics/product/stores/${storeId}/aisles/${aisleCode}${query}`;

    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async launchOptimizationJob(
    params: LaunchOptimizationJobParams
  ): Promise<LaunchOptimizationJobResponse> {
    const url = "/stores/optimizationJobs/";

    const data = params;
    const response = await this.requestHendlerPost(url, data);
    return response.data.responseData;
  }

  async getOptimizationStatus(
    params: GetOptimizationStatusParams
  ): Promise<GetOptimizationStatusResponse> {
    const url = `/stores/optimizationJobs/${params.jobId}`;
    const response = await this.requestHendlerGet(url);

    return response.data.responseData;
  }

  async getOptimizationSolverOutput(
    params: GetSolverOutputParams
  ): Promise<SolverOutputResponse> {
    const { jobId } = params;
    const url = `/stores/optimizationJobs/${jobId}/solver_output`;
    const response = await this.requestHendlerGet(url);
    const solverOutput = solverOutputGenerateId(response.data.responseData);

    return solverOutput;
  }

  async getMetricsForJobId(
    params: getMetricsForJobIdParams
  ): Promise<GetMeticForJobIdResponse> {
    const url = `/stores/metrics/product/jobs/${params.jobId}`;

    const response = await this.requestHendlerGet(url);
    return { ...response.data.responseData, jobId: params.jobId };
  }
  /* istanbul ignore next */
  async getOptimizationJobLimit(params: getOptimizationJobParams): Promise<any> {
    const { storeId, aisleId, limit = 10 } = params;
    let optimizedResponse = [];
    let editedResponse = [];
    let url = `/stores/latest/stores/${storeId}/aisles/${aisleId}/jobs/limit/${limit}`;
    const response = await this.requestHendlerGet(url);
    optimizedResponse =
      Array.isArray(response.data.responseData) &&
      response.data.responseData?.map((job) => ({ ...job, type: "optimized" }));
    const jobIds =
      Array.isArray(optimizedResponse) &&
      optimizedResponse?.map((job) => job.job_id);
    if (jobIds.length) {
      editedResponse = await this.getEditedJobs({ storeId, aisleCode: aisleId, jobIds });
      editedResponse = editedResponse.map(job => ({ ...job, type: 'edited' }));
    }
    return optimizedResponse&& optimizedResponse.concat(editedResponse);
  }

  async getLatestOptimizationJob(params: getOptimizationJobParams): Promise<OptimizationJobsResponse> {
    const { storeId, aisleId } = params;
    const url = `/stores/latest/jobs/stores/${storeId}`;

    const response = await this.requestHendlerGet(url);
    return response.data;
  }

  async serverSideLogout(): Promise<void> {
    const url = "auth/logout";
    const response = await this.axiosInstance.post(url);
    return response
  }

  //TODO we might need this code in the future
  async saveDragAndDropEdits({
    id,
    params,
  }: {
    id: string;
    params: SaveDragAndDropEditsParams;
  }): Promise<SaveDragAndDropEditsResponse> {
    const url = `/solutions/${id}/edit`;
    const data = params;

    const response = await this.requestHendlerPost(url, data);

    return response.data;
  }
  //TODO we might need this code in the future

  async getImages(
    params: GetImagesParams
  ): Promise<GetImagesResponse> {
    const { images } = params;
    const imagesUrlParams = queryString.stringify({ sku: images }, { arrayFormat: 'bracket' });
    const url = `/stores/blob/image?${imagesUrlParams}`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async getDatasetInfo(
  ): Promise<DatasetInfo> {
    const url = `/stores/dataset/info`;
    const response = await this.requestHendlerGet(url);
    let { responseData } = response.data;
    let date = responseData.dataset_info.inv_date.split('-');
    responseData.dataset_info.inv_date = `${date[2]}/${date[1]}/${date[0]}`;
    return responseData;
  }

  async getOptimalSpaceSegment(
    params: OptimalSpaceSegmentParams
  ): Promise<GetOptimalSpaceSegmentResponse> {
    const { storeId, aisleCode, reqBody } = params;
    const url = `/stores/${storeId}/aisles/${aisleCode}/segment_estimate/`;
    const response = await this.requestHendlerPost(url, reqBody);
    let { responseData } = response.data;
    return responseData;
  }

  async getEditedMetricsPog(
    params: GetEditedMetricsPogParams
  ): Promise<GetEditedMetricsPogResponse> {
    const { storeId, aisleCode, jobId } = params;
    const url = `/stores/metrics/pog/store/${storeId}/aisles/${aisleCode}/jobs/${jobId}/editedMetrics`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async getEditedMetricsProduct(
    params: GetEditedMetricsProductParams
  ): Promise<GetEditedMetricsProductResponse> {
    const { storeId, aisleCode, jobId } = params;
    const url = `/stores/metrics/products/store/${storeId}/aisles/${aisleCode}/jobs/${jobId}/editedMetrics`;
    const response = await this.requestHendlerGet(url);
    return { ...response.data.responseData, jobId: params.jobId };
  }

  async getEditedSolverOutput(
    params: GetEditedSolverOutputParams
  ): Promise<SolverOutputResponse> {
    const { storeId, aisleCode, jobId } = params;
    const url = `/stores/pog-editor/store/${storeId}/aisles/${aisleCode}/job/${jobId}/editedSolverOutput`;
    const response = await this.requestHendlerGet(url);
    const solverOutput = solverOutputGenerateId(response.data.responseData);

    return solverOutput;
  }

  async getEditedJobs(params: GetEditedJobParams): Promise<EditedJobResponse> {
    const { storeId, aisleCode, jobIds } = params;
    const jobsUrlParams = queryString.stringify({ job_ids: jobIds }, { arrayFormat: 'bracket' });
    let url = `/stores/pog-editor/store/${storeId}/aisles/${aisleCode}/editedLatestJobs?${jobsUrlParams}`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async saveNewEditedPog(
    params: SaveNewEditedPogParams
  ): Promise<SaveNewEditedPogResponse> {
    const { storeId, aisleCode, reqBody } = params;
    const url = `/stores/pog-editor/v1/store/${storeId}/aisles/${aisleCode}/saveNewEditedPog`;
    const response = await this.requestHendlerPost(url, reqBody);
    let { responseData } = response.data;
    return responseData;
  }

  async updateEditedPog(
    params: SaveNewEditedPogParams
  ): Promise<SaveNewEditedPogResponse> {
    const { storeId, aisleCode, reqBody } = params;
    const url = `/stores/pog-editor/store/${storeId}/aisles/${aisleCode}/updateEditedPog`;
    const response = await this.requestHendlerPost(url, reqBody);
    let { responseData } = response.data;
    return responseData;
  }

  async getFetchPogByStore(params: getFetchPogByStoreParams): Promise<GetFetchPogByStoreResponse> {
    const { storeId } = params;
    const url = `/stores/pog-dashboard/store/${storeId}/fetchPogByStore`;

    const response = await this.requestHendlerGet(url);
    const { responseData } = response.data;
    Array.isArray(responseData) && /* istanbul ignore next */responseData?.forEach(item => {
      item.aisle = item.main_aisle_num !== null ?
        `${item.main_aisle_orientation}${item.main_aisle_num}` :
        null;
      item.aisleForSorting = item.main_aisle_num !== null ?
        `${item.main_aisle_num}${item.main_aisle_orientation}` :
        null;
    });

    return responseData;
  }

  async getAllNotificationBanners(params: getAllNotificationBannersParams): Promise<GetAllNotificationBannersResponse> {
    const url = `/stores/admin/notifications?isExpiredNotification=${params}`
    const response = await this.requestHendlerGet(url);
    const responseWithRemovedAudit =
      Array.isArray(response.data.responseData) &&
      /* istanbul ignore next */response.data.responseData?.map((el) => {
        const { audit, ...rest } = el;
        return rest;
      });
    return responseWithRemovedAudit;
  }

  async getProtoPogs(reqBody: reqBody){
    const url = `/stores/proto_pog/search`
    const response = await this.requestHendlerPost(url, reqBody);
    return response.data.responseData.matched_proto_pog;
  }

  async updateNotificationBanner(params: UpdateNotificationBannerParams): Promise<UpdateNotificationBannerResponse> {
    const { notificationId, reqBody } = params;
    const url = `/stores/admin/notifications/${notificationId}`;

    const response = await this.requestHendlerPost(url, reqBody);
    return response.data.responseData;
  }

  async saveNewNotificationBanner(params: SaveNewNotificationBannerParams): Promise<SaveNewNotificationBannerResponse> {
    const { reqBody } = params;
    const url = `/stores/admin/notifications`;

    const response = await this.requestHendlerPost(url, reqBody);
    return response.data.responseData;
  }

  async deleteNotificationBanner(params: DeleteNotificationBannerParams): Promise<DeleteNotificationBannerResponse> {
    const { notificationId } = params;
    const url = `/stores/admin/notifications/${notificationId}`;

    const response = await this.requestHendlerDelete(url);
    return response.data.responseData;
  }

  async aggregatedJobs(params: AggregatedJobsParams): Promise<AggregatedJobsResponse> {
    const { storeId, aisleId, limit = 10 } = params;
    let url = `/stores/${storeId}/aisles/${aisleId}/jobs/limit/${limit}/aggregatedJobs`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async aggregatedSolverOutput(
    params: AggregatedSolverOutputParams
  ): Promise<AggregatedSolverOutput> {
    const { storeId, aisleId, jobs } = params;
    const jobsParams = jobsGetParams(jobs);
    const url = `/stores/${storeId}/aisles/${aisleId}/aggregatedSolverOutput?${jobsParams}`;
    const response = await this.requestHendlerGet(url);
    const solverOutput = Array.isArray(response.data.responseData) && /* istanbul ignore next */response?.data.responseData.map(solverOutput => solverOutputGenerateId(solverOutput));

    return solverOutput;
  }

  async aggregatedProductMetrics(
    params: AggregatedProductMetricsParams
  ): Promise<AggregatedProductMetricsResponse> {
    const { storeId, aisleCode, jobs } = params;
    const jobsParams = jobsGetParams(jobs);
    const url = `/stores/${storeId}/aisles/${aisleCode}/aggregatedProductMetrics?${jobsParams}`;

    const response = await this.requestHendlerGet(url);
    const [jobMetricMap] = normalizeData(response.data.responseData, 'job_id');
    return jobMetricMap;
  }

  async aggregatedPogMetrics(
    params: AggregatedPogMetricsParams
  ): Promise<AggregatedPogMetricsResponse> {
    const { storeId, aisleCode, jobs } = params;
    const jobsParams = jobsGetParams(jobs);
    const url = `/stores/${storeId}/aisles/${aisleCode}/aggregatedPogMetrics?${jobsParams}`;

    const response = await this.requestHendlerGet(url);
    const [jobMetricMap] = normalizeData(response.data.responseData, 'job_id');
    return jobMetricMap;
  }

  async getProductsInfo(
    params: ProductsInfoParams
  ): Promise<ProductsInfoResponse>{
    const { storeId, aisleCode } = params;
    const url = `/stores/products/info/store/${storeId}/aisles/${aisleCode}`;
    const response = await this.requestHendlerGet(url);
    const [products, productsList] = normalizeData(response.data.responseData, 'product_num');
    return { products, productsList };
  }

  async getProductsPackaging(
    params: ProductsPackagingParams
  ): Promise<ProductsPackagingResponse>{
    const { storeId, aisleCode } = params;
    const url = `/stores/products/packaging?store_num=${storeId}&aisle_code=${aisleCode}`;
    const response = await this.requestHendlerGet(url);
    const [packaging] = normalizeData(response.data.responseData, 'product_num');

    return packaging;
  }

  async saveUserPreference(params: SaveUserPreferenceParams): Promise<SaveUserPreferenceResponse> {
    const { reqBody } = params;
    const url = `stores/users/saveUserPreference`;

    const response = await this.requestHendlerPost(url, reqBody);
    return response.data.responseData;
  }

  async getUserPreference(): Promise<GetUserPreferenceResponse> {
    const url = `/stores/users/getUserPreference`;
    const response = await this.requestHendlerGet(url);
    const data = response.data.responseData;

    if (!data.length) {
      return  {
        "language": "en",
        "workflowTooltips": null,
        "preAssortment": {},
        "compare": null,
        "postAssortment": {},
        "labels": {},
        "pogDashboard": null,
        "pogDashboardStatus": null,
      }
    }

    const pref = data[0];
    const { preAssortment, postAssortment } = pref;
    return {
      ...pref,
      preAssortment: preAssortment === null ? {} : preAssortment,
      postAssortment: postAssortment === null ? {} : postAssortment,
    };
  }

  async getProductsBlocking(
    params: BlockingParams
  ): Promise<BlockingResponse>{
    const { storeId, aisleCode } = params;
    const url = `/stores/products/blocking/store/${storeId}/aisles/${aisleCode}`;

    const response = await this.requestHendlerGet(url);

    return response.data.responseData;
  }

  async saveMyNotes(
    params: SaveMyNotesParams
  ): Promise<SaveMyNotesResponse> {
    const url = `/stores/userNote/saveUserNote`;
    const response = await this.requestHendlerPost(url, params);
    const { responseData } = response.data;
    return responseData;
  }

  async getProtoLinear(params:ProtoLinearParams): Promise<ProtoLinearResponse>{
    const  {aisleCode,label,orientation} = params
    const url = `/stores/aisles/${aisleCode}/linears?side=${orientation}&labels=${label}`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async flipPog(
    params: FlipPogParams
  ): Promise<FlipPogResponse> {
    const { storeId, aisleCode, job_id,  is_edited} = params;
    const url = `/stores/pog-editor/v1/store/${storeId}/aisles/${aisleCode}/saveNewMirroredPog`;
    const response = await this.requestHendlerPost(url, { job_id, is_edited });
    const { responseData } = response.data;
    return { ...responseData, solver_output: solverOutputGenerateId(responseData.solver_output)};
  }

  async getVendorPegboards(): Promise<{
    [key: string]: Array<VendorPegbordAPIItem>
  }>{
    const url = `/stores/fixtures/pegboards`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async getMatchedPegboardConfig(
    params: GetMatchedPegboardConfigParams
  ): Promise<GetMatchedPegboardConfigResponse> {
    const { vendor, start_hole_x, start_hole_y, reqBody } = params;
    const url = `/stores/fixtures/pegboards/match?vendor=${vendor}&start_hole_x=${start_hole_x}&start_hole_y=${start_hole_y}`;
    const response = await this.requestHendlerPost(url, reqBody);
    return response.data.responseData;
  }

  async getFixtures(): Promise<GetFixturesResponse> {
    const url = `/stores/pog/fixtures`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }

  async getFixturesNotchesHoles(): Promise<GetFixturesNotchesHolesResponse> {
    const url = `/stores/fixtures/notches_holes`;
    const response = await this.requestHendlerGet(url);
    return response.data.responseData;
  }
}
