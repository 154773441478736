import { createAction } from "typesafe-actions";
import { NotificationSpecification } from "../../types/models";

export const actions = {
  addNotification: createAction("notification/ADD_NOTIFICATION")<
    NotificationSpecification
  >(),
  dismissNotification: createAction("notifications/DISMISS_NOTIFICATION")<
    string | number
  >(),
};
