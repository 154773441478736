import { AxiosError } from "axios";
import { createAsyncAction } from "typesafe-actions";

import type {
  BlockingParams,
  BlockingResponse
} from "./types";

export const actions = {
    getProductsBlocking: createAsyncAction(
    "api/GET_BLOCKING_REQUEST",
    "api/GET_BLOCKING_SUCCESS",
    "api/GET_BLOCKING_FAILURE"
  )<BlockingParams, BlockingResponse, AxiosError>(),
};
