import { createAction } from "typesafe-actions";
import { FixtureType } from "../../components/ViewAndEdit/editorConstants";
import { WarningData, WarningsData } from "../job/types";
import { EditMode, LegendClickTrack } from "./reducer";
import type {
  FacingsEntitiesParams,
  FixtureEntitiesParams,
  HistoryParams,
  MultipleFacingsEntitiesParams,
  MultipleFixtureEntitiesParams,
} from "./types";
import { PDFDownloadStatus } from '../../types/responses';

export const actions = {
  setSkuNumberToShown: createAction("pogEditor/SET_SKU_NNUMBER_TO_SHOWN")<boolean>(),
  setDisableRedOverlapHightlights: createAction("pogEditor/SET_CPU_PERFORMANCE")<boolean>(),
  setHidePegholes: createAction("pogEditor/SET_PEGHOLE_TO_SHOWN")<boolean>(),
  setShowProductImages: createAction("pogEditor/SET_SHOW_PRODUCT_IMAGES")<boolean>(),
  setSelectedSegment: createAction("pogEditor/SET_SELECTED_SEGMENT")<number | null>(),
  setLegendOpened: createAction("pogEditor/SET_LEGEND_OPENED")<boolean>(),
  setProductHighlightOpened: createAction("pogEditor/SET_PRODUCT_HIGHLIGHT_OPENED")<boolean>(),
  setLegendClickTrack: createAction("pogEditor/SET_LEGEND_CLICK_TRACK")<LegendClickTrack>(),
  setFiltersOpened: createAction("pogEditor/SET_FILTERS_OPENED")(),
  setClipboardOpened: createAction("pogEditor/SET_CLIPBOARD_OPENED")<boolean>(),
  setWorkbenchOpened: createAction("pogEditor/SET_WORKBENCH_OPENED")<boolean>(),
  setMerchNotesExpanded: createAction("pogEditor/SET_MERCH_NOTES_EXPANDED")<boolean>(),
  setRestart: createAction("pogEditor/SET_RESTART")<boolean>(),
  setReset: createAction("pogEditor/SET_RESET")<void>(),
  setSelectedFacing: createAction("pogEditor/SET_SELECTED_FACING")<number | null>(),
  setSelectedFacingsGroup: createAction("pogEditor/SET_SELECTED_FACINGS_GROUP")<string>(),
  setNotificationModal: createAction("pogEditor/SET_NOTIFICATION_MODAL")<boolean>(),
  undoHistoryStep: createAction("pogEditor/UNDO_HISTORY_STEP")<void>(),
  redoHistoryStep: createAction("pogEditor/REDO_HISTORY_STEP")<void>(),
  setHistory: createAction("pogEditor/SET_HISTORY")<HistoryParams>(),
  setPopDrawerOpened: createAction("pogEditor/SET_POP_DRAWER_OPENED")<boolean | undefined>(),
  setVisualizerAlert: createAction("pogEditor/SET_VISUALIZER_ALERT")<WarningData | null>(),
  setAlertOpened: createAction("pogEditor/SET_ALERT_OPENED")<boolean>(),
  setAlerts: createAction("pogEditor/SET_ALERTS")<WarningsData>(),
  setAlert: createAction("pogEditor/SET_ALERT")<WarningData>(),
  setAlertsIndicator: createAction("pogEditor/SET_ALERTS_INDICATOR")<boolean>(),
  setEdit: createAction("pogEditor/SET_EDIT_MODE")<boolean>(),
  selectedReset: createAction("pogEditor/SELECTED_RESET")<void>(),
  setFacingsEntities: createAction("pogEditor/SET_FACINGS_ENTITIES")<FacingsEntitiesParams>(),
  setMultipleFacingsEntities: createAction("pogEditor/SET_MALTIPLE_FACINGS_ENTITIES")<MultipleFacingsEntitiesParams>(),
  setSelectedFixtureType: createAction("pogEditor/SET_SELECTED_FIXTURE_TYPE")<FixtureType | string>(),
  setEditType: createAction("pogEditor/SET_EDIT")<EditMode | null>(),
  setSelectedFixtureIds: createAction("pogEditor/SET_SELECTED_FIXTURES")<MultipleFixtureEntitiesParams>(),
  setToggleAlert: createAction("pogEditor/SET_TOGGLE_ALERT")<boolean>(),
  setStrikeZoneEnabled: createAction("pogEditor/SET_STRIKE_ZONE_ENABLED")<boolean>(),
  setRemovedFacings: createAction("pogEditor/SET_REMOVED_FACINGS")<Array<string>>(),
  setActiveClipboardSku: createAction("pogEditor/SET_ACTIVE_CLIPBOARD_SKU")<Array<string>>(),
  removedReset: createAction("pogEditor/REMOVED_RESET")<void>(),
  setPogHeightEnabled: createAction("pogEditor/SET_POG_HEIGHT_ENABLED")<boolean>(),
  setWorkbenchScrollWithPog: createAction("pogEditor/SET_WORKBENCH_SCROLL_WITH_POG")<boolean>(),
  setExportStatus: createAction("pogEditor/SET_EXPORT_STATUS")<null | PDFDownloadStatus>()
};
