import { User } from "../../types/api";
import { ActionType, getType } from "typesafe-actions";
import { actions as apiActions } from "../api/actions";
import { actions } from "./actions";
import { ColorStatus, LabelStatus, LatestJobs } from "../../types/responses";
import { defaultCustomizeValues } from "../assortment/reducer";
import { Checkboxes } from '../../types/models';

export type AisleFilterState = {
  confidenceLevel: string[];
  status: string[]
};

export type SortKey =
  "confidenceHighLow" |
  "confidenceLowHigh" |
  "pogNumberHighLow" |
  "pogNumberLowHigh" |
  "categoryAZ" |
  "categoryZA" |
  "newOld" |
  "oldNew";

export const statusFiltersInitial = ["optimized", "notOptimized"];

export const INITIAL_FILTERS = {
  confidenceLevel: ["GREEN", "BLUE", "YELLOW", "ORANGE", "PURPLE", "RED"],
  status: statusFiltersInitial
}

export type ConfidenceLevel = keyof ColorStatus;
export type Status = "optimized" | "notOptimized";
export type Aisle = {
  code: string;
  category: string,
  confidenceLevel: ConfidenceLevel,
  pogNumber: string;
  linear: number;
  depth: number;
  height: number;
  status: keyof ColorStatus;
  label_support: string;
  time_limit_in_seconds: number;
  label_status: LabelStatus;
}
export type FetchPogByStore = {
  id: string,
  store_num: string;
  aisle_code: string;
  aisle_category: string;
  business_category: string;
  sales_in_dollars: number;
  productivity_estimate: number;
  camps_dollar: number;
  lost_sales_total: number;
  sku_count: number;
  single_facing_sku_count: number;
  current_setup_low_wos_count: number,
  fill_trips: number;
  inv_date: string;
  delivery_date: string;
  audit: {
    created_by: string;
    created_date_time: string;
  },
  main_aisle_orientation: string;
  main_aisle_num: string;
  lost_sales_total_dollar: number;
}

export type userPreferences = {
  language: string,
  workflowTooltips: boolean,
  preAssortment: Checkboxes,
  compare: {},
  postAssortment: Checkboxes,
  pogDashboard: string[],
  pogDashboardStatus: string[]
  labels: {}
}
export interface UserState {
  availableStores?: Array<number>;
  aisleFilters: AisleFilterState;
  availableAisles: Aisle[] | null;
  currentUser: User | null;
  datasetInfo: string;
  latestJobs: LatestJobs | null;
  sortKey: SortKey;
  searchValue: string;
  fetchPogByStore: FetchPogByStore | null;
  admin: boolean;
  userPreferences: userPreferences;
  searchValueForVisualizer: string;
  isVisualizerSearchEnabled: boolean;
}

let defaultUserPreferences = {
  language: 'en' as string,
  workflowTooltips: true,
  preAssortment: defaultCustomizeValues,
  compare: {},
  postAssortment: defaultCustomizeValues,
  pogDashboard: [],
  pogDashboardStatus:  [],
  labels: {},
};
// try {
//   const upSession = sessionStorage.getItem('userPreferences');
//   if (upSession != null) {
//     const up = JSON.parse(upSession);
//     defaultUserPreferences = Object.prototype.toString.call(up) === '[object Object]' ? up : defaultUserPreferences;
//   }
// } catch(e) {
//   //defaultUserPreferences = null;
// }

export const INITIAL_STATE: UserState = {
  currentUser: null,
  availableAisles: null,
  sortKey: "categoryAZ",
  aisleFilters: INITIAL_FILTERS,
  latestJobs: null,
  searchValue: "",
  datasetInfo: "",
  userPreferences: defaultUserPreferences,
  fetchPogByStore: null,
  admin: false,
  searchValueForVisualizer: "",
  isVisualizerSearchEnabled: false
};

export function user(
  state: UserState = INITIAL_STATE,
  action: ActionType<typeof apiActions | typeof actions>
): UserState {
  switch (action.type) {
    case getType(apiActions.getAvailableStores.success): {
      const { responseData, admin } = action.payload;
      return {
        ...state,
        availableStores: responseData.sort(),
        admin,
      };
    }
    case getType(apiActions.getUserPreference.success): {
      // const { language, workflowTooltips, preAssortment, compare, postAssortment } = action.payload;
      // console.log("🚀 ~ file: reducer.ts ~ line 111 ~ casegetType ~ action.payload", action.payload)
      saveUserPreferenceSession(action.payload);
      return {
        ...state,
        // userPreferences: { language, workflowTooltips, preAssortment, compare, postAssortment }

        userPreferences: action.payload
      };
    }
    case getType(apiActions.saveUserPreference.success): {
      //saveUserPreferenceSession(action.payload);
      return {
        ...state,
        //userPreferences: action.payload
      };
    }
    case getType(actions.setLanguage): {
      return {
        ...state,
        userPreferences: {...state.userPreferences, language: action.payload}
      };
    }
    case getType(actions.setworkflowTooltips): {
      updateWorkflowTooltipsSession(action.payload);
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          workflowTooltips: action.payload
        }
      };
    }
    case getType(actions.setLabels): {
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          labels: action.payload
        }
      };
    }
    case getType(actions.setAssortmentFields): {
      updateAssortmentFields(action.payload);
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          ...action.payload
        }
      };
    }
    case getType(apiActions.getAvailableAisles.success): {
      return {
        ...state,
        availableAisles: action.payload.map((item) => {
          const { aisle_geometry: { blocks } } = item;
          return {
            code: item.code,
            category: item.category,
            confidenceLevel: item.status,
            pogNumber: item.code,
            linear: blocks[0].num_segments,
            depth: blocks[0].depth,
            height: blocks[0].height,
            status: item.status,
            label_support:item.label_support,
            time_limit_in_seconds: item.time_limit_in_seconds,
            label_status: item.label_status
          }
        })
      };
    }
    case getType(apiActions.getDatasetInfo.success): {
      const { dataset_info: { inv_date: invDate = "" } = {} } = action.payload;

      return {
        ...state,
        datasetInfo: invDate
      };
    }
    case getType(apiActions.setUserInfo.success): {
      const { name, email } = action.payload;
      return {
        ...state,
        currentUser: {
          name,
          email,
        },
      };
    }
    case getType(apiActions.getLatestJobs.success): {
      return {
        ...state,
        latestJobs: action.payload
      };
    }
    case getType(apiActions.getFetchPogByStore.success): {
      return {
        ...state,
        fetchPogByStore: action.payload
      };
    }
    case getType(actions.setSortBy): {
      return {
        ...state,
        sortKey: action.payload
      };
    }
    case getType(actions.setConfidenceLevelFilter): {
      return {
        ...state,
        aisleFilters: {
          ...state.aisleFilters,
          confidenceLevel: action.payload
        }
      };
    }
    case getType(actions.setPogDashboardStatus): {
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          pogDashboardStatus: action.payload
        }
      };
    }

    case getType(actions.setStatusFilter): {
      return {
        ...state,
        aisleFilters: {
          ...state.aisleFilters,
          status: action.payload
        }
      };
    }
    case getType(actions.setTargetSearchValue): {
      return {
        ...state,
        searchValue: action.payload
      };
    }
    case getType(actions.setInitialFilters): {
      return {
        ...state,
        aisleFilters: INITIAL_FILTERS
      };
    }
    case getType(actions.setsearchValueForVisualizer): {
      return {
        ...state,
        searchValueForVisualizer: action.payload
      };
    }
    case getType(actions.setVisualizerSearchEnabled): {
      return {
        ...state,
        isVisualizerSearchEnabled: action.payload
      };
    }
    case getType(actions.setInitialState): {
      return {
        ...state,
        availableAisles: null,
        aisleFilters: INITIAL_FILTERS,
        latestJobs: null,
        searchValue: "",
        searchValueForVisualizer: ""
      };
    }
    case getType(apiActions.setUserInfo.failure):
      return INITIAL_STATE;
    default:
      return state;
  }
}

const saveUserPreferenceSession = (preferences: userPreferences) => {
  if (Object.prototype.toString.call(preferences) == '[object Object]') {
    preferences.workflowTooltips = preferences.workflowTooltips?.toString() === 'true' ? true : false;
    sessionStorage.setItem('userPreferences', JSON.stringify(preferences));
  } else {
    sessionStorage.setItem('userPreferences', JSON.stringify(defaultUserPreferences));
  }
};

const updateWorkflowTooltipsSession = (show: boolean) => {
  try {
    const up = JSON.parse(sessionStorage.getItem('userPreferences') || '');
    up.workflowTooltips = show;
    sessionStorage.setItem('userPreferences', JSON.stringify(up));
  } catch(e) {
    sessionStorage.setItem('userPreferences', JSON.stringify({...defaultUserPreferences, workflowTooltips: show}));
  }
}

const updateAssortmentFields = (data: Partial<userPreferences>) => {
  try {
    const up = {...JSON.parse(sessionStorage.getItem('userPreferences') || ''), ...data};
    sessionStorage.setItem('userPreferences', JSON.stringify(up));
  } catch(e) {
    sessionStorage.setItem('userPreferences', JSON.stringify({...defaultUserPreferences, ...data}));
  }
}
