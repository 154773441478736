import { ActionType, getType } from "typesafe-actions";

import { actions } from "./actions";
import { WorkFlowState } from "./types";

const INITIAL_STATE: WorkFlowState = {
  activeStep: -1,
  showVerticalWorkFlow: true,
  showHorizontalWorkFlow: false,
  pogDashboardTableHeight: 0,
  toolTipDesc: {
    showDesc: true,
    activeDesc: 0,
    desc: '',
    toolTipHeight: 0
  },
  showDescStore: {
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
  }
};

export function workFlow(
  state: WorkFlowState = INITIAL_STATE,
  action: ActionType<typeof actions>
): WorkFlowState {
  switch (action.type) {
    case getType(actions.setActiveStep):
      return {
        ...state,
        activeStep: action.payload,
      };
    case getType(actions.setShowVerticalWorkFlow):
      return {
        ...state,
        showVerticalWorkFlow: action.payload,
      };
    case getType(actions.setShowHorizontalWorkFlow):
      return {
        ...state,
        showHorizontalWorkFlow: action.payload,
      };
    case getType(actions.setPogDashboardTableHeight):
      return {
        ...state,
        pogDashboardTableHeight: action.payload,
      };
    case getType(actions.setWorkFlowToolTipDesc):
      return {
        ...state,
        toolTipDesc: action.payload,
      };
    case getType(actions.setShowDescStore):
      return {
        ...state,
        showDescStore: {
          ...state.showDescStore,
          ...action.payload,
        },
      };
  }
  return state;
}
