import { ActionType, getType } from "typesafe-actions";

import { actions } from "./actions";
import { TetrisGlobalState } from "./types";

export const INITIAL_STATE: TetrisGlobalState = {
  help: {
    showHelpModal: false
  },
  video: {
    playVideo: "",
    miniPlayer: false
  }
};

export function globalState(
  state: TetrisGlobalState = INITIAL_STATE,
  action: ActionType<typeof actions>
): TetrisGlobalState {
  switch (action.type) {
    case getType(actions.showHelpModal):
      return {
        ...state,
        help: {
          ...state.help,
          showHelpModal: action.payload,
        },
      };
    case getType(actions.playVideo):
      return {
        ...state,
       video: {
        ...state.video,
        playVideo: action.payload
       }
      };
    case getType(actions.miniPlayer):
      return {
        ...state,
        video: {
        ...state.video,
        miniPlayer: action.payload
        }
      };
  }
  return state;
}
