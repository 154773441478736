import { ActionType, getType } from "typesafe-actions";

import { actions } from "./actions";
import { actions as apiActions } from "../api/actions";
import { Solution } from "../../types/models";
import { convertMetricsResponse } from "../../helpers/data";
import { AisleMetricsProperties, GetStateResponse } from "../../types/responses";
import { MetricJobs } from "../../types/models";
import { maxPogVersions } from '../../utils/constants';

export type ComparisonState = {
  current?: AisleMetricsProperties;
  solutions: { [key: string]: AisleMetricsProperties };
  knownSolutionsById: { [key: string]: Solution };
  selectedVersions: { [key: string]: boolean };
  postSolver: boolean;
  currentSelected: boolean;
  versionForReview: string;
  metricJobs: MetricJobs;
};

const INITIAL_STATE: ComparisonState = {
  current: undefined,
  solutions: {},
  knownSolutionsById: {},
  selectedVersions: {}, //TODO rewrite to array!
  postSolver: false,
  currentSelected: false,
  versionForReview: '',
  metricJobs: {},
};

export function comparison(
  state: ComparisonState = INITIAL_STATE,
  action: ActionType<typeof actions | typeof apiActions>
): ComparisonState {
  switch (action.type) {
    case getType(apiActions.getCurrentState.success): {
      const { payload } = action;

      return {
        ...state,
        current: convertMetricsResponse(payload)
      }
    }

    case getType(apiActions.getOptimizedState.success):
    case getType(apiActions.getEditedMetricsPog.success): {
      const { payload: {jobId, ...metrics } } = action;
      const convertedMetrics = convertMetricsResponse(metrics as GetStateResponse)
      const solutions = {...state.solutions, [jobId]: convertedMetrics}

      return {
        ...state,
        solutions
      };
    }

    case getType(actions.setInitialState):
      return INITIAL_STATE;

    case getType(actions.setPostSolver):
      const { payload } = action;

      return {
        ...state,
        postSolver: payload
      };

    case getType(actions.setSelectedPogVersions): {
      const { payload } = action;
      const { versionForReview } = state;
      const newState = {
        ...state,
        selectedVersions: payload,
      };
      const jobIds = Object.keys(payload).filter(jobId => payload[jobId]);
      const versionForReviewExist = jobIds.includes(versionForReview);
      if (!versionForReviewExist) {
        newState.versionForReview = jobIds[0];
      }
      return newState;
    }

    case getType(actions.setCurrentSelected): {
      const { payload } = action;
      return {
        ...state,
        currentSelected: payload
      };
    }

    case getType(actions.setVersionForReview): {
      const { payload } = action;
      return {
        ...state,
        versionForReview: payload
      };
    }

    case getType(apiActions.saveNewEditedPog.success):
    case getType(apiActions.updateEditedPog.success): {
      const { payload } = action;

      let reachedMax = false;
      let count = 0;
      const newState: {
        [key: string]: boolean;
      } = {};
      Object.keys(state.selectedVersions).forEach((version) => {
        if (state.selectedVersions[version]) {
          count++;
        }
        if (count !== 1) {
          newState[version] = state.selectedVersions[version];
        }

        if (count === maxPogVersions) {
          reachedMax = true;
        }
      });

      return {
        ...state,
        versionForReview: payload.job.job_id,
        selectedVersions: reachedMax ? {
          ...newState,
          [payload.job.job_id]: true
        } : {
          ...state.selectedVersions,
          [payload.job.job_id]: true,
        }
      };
    }

    case getType(actions.setMetricJobs):
    case getType(apiActions.aggregatedProductMetrics.success): {
      const { payload } = action;
      return {
        ...state,
        metricJobs: payload
      };
    }

    case getType(apiActions.flipPog.success):
      const { job } = action.payload;
      const newMirroredJob = { [job.job_id]: true };
      const { versionForReview, selectedVersions } = state;
      const newState = {
        ...state,
        selectedVersions: { ...selectedVersions, ...newMirroredJob },
      };
      const jobIds = Object.keys(newMirroredJob).filter(jobId => newMirroredJob[jobId]);
      const versionForReviewExist = jobIds.includes(versionForReview);
      if (!versionForReviewExist) {
        newState.versionForReview = jobIds[0];
      }
      return newState;

    default:
      return state;
  }
}
