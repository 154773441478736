import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
// import { makeServer } from './server/server';

// if (process.env.NODE_ENV === "development") {
//   console.log("dev")
//   makeServer({ environment: "development" })
// }

// As we are developping with a 2nd theme,
// we cannot have React.StrictMode to be able to switch
// see https://github.com/mui-org/material-ui/issues/23334
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
